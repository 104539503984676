class AutomationActionTypes {}
AutomationActionTypes.FETCH_AUTOMATIONS_RESPONSE = "FETCH_AUTOMATIONS_RESPONSE"
AutomationActionTypes.AUTOMATIONS_LIST_LOADING = "AUTOMATIONS_LIST_LOADING"
AutomationActionTypes.ROLLBACK_AUTOMATIONS_PAGE = "ROLLBACK_AUTOMATIONS_PAGE"
AutomationActionTypes.UPDATE_ITEMS_PER_PAGE = "UPDATE_ITEMS_PER_PAGE"
AutomationActionTypes.RESET_AUTOMATIONS_PAGINATION = "RESET_AUTOMATIONS_PAGINATION"
AutomationActionTypes.UPDATE_AUTOMATIONS_PAGE = "UPDATE_AUTOMATIONS_PAGE"
AutomationActionTypes.AUTOMATION_STATUS_LOADING = "AUTOMATION_STATUS_LOADING"
AutomationActionTypes.ACTIVATE_AUTOMATION_RESPONSE = "ACTIVATE_AUTOMATION_RESPONSE"
AutomationActionTypes.PAUSE_AUTOMATION_RESPONSE = "PAUSE_AUTOMATION_RESPONSE"
AutomationActionTypes.PAUSE_AUTOMATION_ERROR_RESPONSE = "PAUSE_AUTOMATION_ERROR_RESPONSE"
AutomationActionTypes.ACTIVATE_AUTOMATION_ERROR_RESPONSE = "ACTIVATE_AUTOMATION_ERROR_RESPONSE"

export default AutomationActionTypes
