import ActionTypes from "../../../common/actions/actionTypes"
import { isNullOrEmpty, mapFromArrayOfObjects } from "../../../utils/funcUtils"
import CampaignsConstsV2 from "../../../campaignsV2/campaignsConsts"
import Consts from "../../../app/consts"
import ProfilePageActionTypes from "./profilePageActionTypes"

const profileInitialState = {
  isLoading: false,
  personalDetails: {
    isLoading: false,
    isCropPageOpen: false,
    image: null,
  },
  customInterfaceV2: createCustomInterfaceStateV2(),
  savedFilters: [],
}

function profilePageReducer(state = profileInitialState, action) {
  if (state === undefined || action === undefined) {
    return []
  }

  switch (action.type) {
    case ProfilePageActionTypes.PROFILE_PAGE_LOADING:
      return Object.assign({}, state, {
        isLoading: true,
      })

    case ProfilePageActionTypes.PERSONAL_DETAILS_PAGE_LOADING:
      return Object.assign({}, state, {
        personalDetails: Object.assign({}, state.personalDetails, {
          isLoading: true,
        }),
      })

    case ProfilePageActionTypes.UPDATE_CURRENT_USER_RESPONSE:
      return Object.assign({}, state, {
        personalDetails: Object.assign({}, state.personalDetails, {
          isLoading: false,
        }),
      })

    case ProfilePageActionTypes.OPEN_CROP_PAGE:
      return Object.assign({}, state, {
        personalDetails: Object.assign({}, state.personalDetails, {
          isCropPageOpen: true,
          image: action.file,
        }),
      })

    case ProfilePageActionTypes.CLOSE_CROP_PAGE:
      return Object.assign({}, state, {
        personalDetails: Object.assign({}, state.personalDetails, {
          isCropPageOpen: false,
          image: null,
        }),
      })

    case ActionTypes.CUSTOM_INTERFACES_LOADED_V2:
      let customInterfaceV2 = new Map()
      let campaignsInterfaceV2 = new Map()
      let userCustomInterfaceV2 = mapFromArrayOfObjects(action.customInterfaceV2.get("campaigns"), "name")

      for (var name of state.customInterfaceV2.get("campaigns").keys()) {
        campaignsInterfaceV2.set(name, {
          ...state.customInterfaceV2.get("campaigns").get(name),
          ...userCustomInterfaceV2.get(name),
        })
      }
      customInterfaceV2.set("campaigns", campaignsInterfaceV2)

      return Object.assign({}, state, {
        customInterfaceV2,
      })

    case ProfilePageActionTypes.UPDATE_CUSTOM_INTERFACE_ROW_V2:
      return Object.assign({}, state, {
        customInterfaceV2: updateCustomInterfaceStateV2(state, action),
      })

    case ProfilePageActionTypes.UPDATE_CUSTOM_INTERFACE_SECTION_V2:
      var clonedCustomizeInterfaceV2 = new Map(state.customInterfaceV2)
      clonedCustomizeInterfaceV2.set(action.sectionName, action.section)
      return Object.assign({}, state, {
        customInterfaceV2: clonedCustomizeInterfaceV2,
      })

    case ActionTypes.SAVED_FILTERS_LOADED:
      return Object.assign({}, state, {
        savedFilters: action.savedFilters,
      })

    case ActionTypes.ADD_SAVED_FILTERS:
      var savedFiltersArr = state.savedFilters.slice(0)
      savedFiltersArr.push(action.savedFilters)

      return Object.assign({}, state, {
        savedFilters: savedFiltersArr,
      })

    case ActionTypes.DELETE_SAVED_FILTERS:
      var savedFiltersArr = state.savedFilters.slice(0).filter((savedFilter) => savedFilter.id !== action.filterId)

      return Object.assign({}, state, {
        savedFilters: savedFiltersArr,
      })

    default:
      return state
  }
}

function updateCustomInterfaceStateV2(state, action) {
  let customInterfaceMap = new Map(state.customInterfaceV2)
  customInterfaceMap.set(
    action.section,
    customInterfaceMap.get(action.section).set(action.name, {
      ...state.customInterfaceV2.get(action.section).get(action.name),
      order: action.order,
      status: action.status,
    })
  )

  return customInterfaceMap
}

function createCustomInterfaceStateV2() {
  let customInterface = new Map()
  let columnsMap = new Map()
  let counter = 0

  Object.keys(CampaignsConstsV2.LIST_COLUMNS.header_columns).forEach((key) => {
    if (!isNullOrEmpty(CampaignsConstsV2.LIST_COLUMNS.header_columns[key].title)) {
      columnsMap.set(CampaignsConstsV2.LIST_COLUMNS.header_columns[key].fieldName, {
        options: CampaignsConstsV2.LIST_COLUMNS.header_columns[key],
        columnName: CampaignsConstsV2.LIST_COLUMNS.header_columns[key].title,
        name: CampaignsConstsV2.LIST_COLUMNS.header_columns[key].fieldName,
        status: Consts.CUSTOM_INTERFACE_FIELD_STATUS.LOCK,
        order: counter,
      })

      counter++
    }
  })

  Object.keys(CampaignsConstsV2.LIST_COLUMNS.data_columns).forEach((key) => {
    if (CampaignsConstsV2.LIST_COLUMNS.data_columns[key].isDefaultField) {
      columnsMap.set(CampaignsConstsV2.LIST_COLUMNS.data_columns[key].fieldName, {
        options: CampaignsConstsV2.LIST_COLUMNS.data_columns[key],
        columnName: CampaignsConstsV2.LIST_COLUMNS.data_columns[key].title,
        name: CampaignsConstsV2.LIST_COLUMNS.data_columns[key].fieldName,
        status: Consts.CUSTOM_INTERFACE_FIELD_STATUS.SHOW,
        order: counter,
      })
    } else {
      columnsMap.set(CampaignsConstsV2.LIST_COLUMNS.data_columns[key].fieldName, {
        options: CampaignsConstsV2.LIST_COLUMNS.data_columns[key],
        columnName: CampaignsConstsV2.LIST_COLUMNS.data_columns[key].title,
        name: CampaignsConstsV2.LIST_COLUMNS.data_columns[key].fieldName,
        status: Consts.CUSTOM_INTERFACE_FIELD_STATUS.HIDE,
        order: counter,
      })
    }

    counter++
  })

  customInterface.set("campaigns", columnsMap)
  return customInterface
}

export { profilePageReducer }
