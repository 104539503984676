import React from "react"
import { connect } from "react-redux"
import ProviderAccountDropdown from "./dropdowns/targetingGroupsDropdowns/providerAccountDropdown"
import { duplicateTargetingGroup, removeTargetingGroup, toggleTargetingGroupOpen } from "../campaignCreationActions"
import CountriesAndCountryGroupsDropdown from "./dropdowns/targetingGroupsDropdowns/countriesAndCountryGroupsDropdown"
import PlatformSelection from "./dropdowns/targetingGroupsDropdowns/platformSelection"
import PlacementsDropdown from "./dropdowns/targetingGroupsDropdowns/placementsDropdown"
import TagsDropdown from "./dropdowns/targetingGroupsDropdowns/tagsDropdown"
import NameSuffix from "./fields/targetingGroupsFields/nameSuffix"
import WarningIconSmall from "../../resources/svgs/WarningIconSmall.svg"
import { KeyboardArrowDown, KeyboardArrowRight } from "@material-ui/icons"
import { getTargetingGroupSelectedPlatformsSummaryText } from "../../utils/campaignUtilsV2"
import TargetingGroupPresetPopup from "../../settings/components/targetingGroupPresets/targetingGroupPresetPopup"
import ConversionEventsDropdown from "./dropdowns/targetingGroupsDropdowns/conversionEventsDropdown"
import CampaignTypeDropdown from "./dropdowns/targetingGroupsDropdowns/campaignTypeDropdown"
import BrowsersDropdown from "./dropdowns/targetingGroupsDropdowns/browsersDropdown"
import InitialBidAndBudget from "./dropdowns/targetingGroupsDropdowns/InitialBidAndBudget"
import { updateTargetingGroup } from "../campaignCreationActions"
import BidTypes from "../../common/consts/bidTypes"
import StatusSwitch from "./dropdowns/targetingGroupsDropdowns/statusSwitch"
import DeviceTargetingControl from "./dropdowns/targetingGroupsDropdowns/deviceTargetingControl"
import CampaignsConsts from "../../campaignsV2/campaignsConsts"

class CampaignCreationTargetingGroup extends React.Component {
  constructor(props) {
    super(props)
    const selectedCampaignType = props.campaignTargetingGroup.campaignTypes.find((type) => type.selected) || {}

    this.state = {
      groupOptionsMenuOpen: false,
      groupOptionsMenuAnchorEl: null,
      targetingGroupPresetDialogOpen: false,
      targetingGroupDataForPreset: null,
      bidType: selectedCampaignType?.id,
    }
  }

  componentDidMount() {
    if (this.props.isRsoc) {
      this.updateConversionEventsList()
    }
  }

  openGroupOptionsMenu = (event) => {
    event.stopPropagation()

    this.setState({
      groupOptionsMenuOpen: true,
      groupOptionsMenuAnchorEl: event.currentTarget,
    })
  }

  closeGroupOptionsMenu = (event) => {
    event.stopPropagation()

    this.setState({
      groupOptionsMenuOpen: false,
    })
  }

  deleteTargetingGroup = (event) => {
    this.closeGroupOptionsMenu(event)
    this.props.dispatch(removeTargetingGroup(this.props.groupIndex))
  }

  duplicateTargetingGroup = (event) => {
    this.closeGroupOptionsMenu(event)
    this.props.dispatch(duplicateTargetingGroup(this.props.groupIndex, this.props.campaignTargetingGroup))
  }

  generateValidationError = (validationErrors, field) => {
    let error = validationErrors.get(field)

    if (!this.props.validationErrorsVisible) {
      return null
    }

    if (error) {
      return (
        <div className="validation-error">
          <WarningIconSmall />
          <span className="text">{validationErrors.get(field)}</span>
        </div>
      )
    }

    return null
  }

  renderGroupOptionsMenu = () => {
    return (
      <>
        <div className="group-options-menu">
          <span className="clickable target-group-action-link" onClick={this.duplicateTargetingGroup}>
            Duplicate
          </span>
          <span className="clickable target-group-action-link" onClick={this.openTargetingGroupPresetPopup}>
            Save
          </span>
          <span className="clickable target-group-action-link" onClick={this.deleteTargetingGroup}>
            Delete
          </span>
        </div>
      </>
    )
  }

  renderGroupOpenSwitch = () => {
    let arrow

    if (this.props.campaignTargetingGroup.isOpen) {
      arrow = <KeyboardArrowDown />
    } else {
      arrow = <KeyboardArrowRight />
    }

    return <span>{arrow}</span>
  }

  getSelectedPlatformsSummaryText = () => {
    return getTargetingGroupSelectedPlatformsSummaryText(
      this.props.selectedSource.id,
      this.props.campaignTargetingGroup
    )
  }

  handleBidTypeSelection = (bidType) => {
    if ([BidTypes.roas.value, BidTypes.highest_value.value].includes(bidType)) {
      this.updateConversionEventsList([])
    }
    this.setState({ bidType })
  }

  renderGroupFields = () => {
    let providerAccountValidationErrors = null
    let platformsValidationErrors = null
    let deviceTargetingValidationErrors = null
    let conversionEventsValidationErrors = null
    let nameSuffixValidationErrors = null
    let initialBidValidationErrors = null
    let initialBudgetValidationErrors = null
    let duplicateTargetingGroupsErrors = null
    let bidTypeValidationErrors = null

    let validationErrors = this.props.validationErrors
    if (validationErrors) {
      providerAccountValidationErrors = this.generateValidationError(validationErrors, "providerAccount")
      platformsValidationErrors = this.generateValidationError(validationErrors, "platforms")
      deviceTargetingValidationErrors = this.generateValidationError(validationErrors, "deviceTargeting")
      conversionEventsValidationErrors = this.generateValidationError(validationErrors, "conversionEvents")
      nameSuffixValidationErrors = this.generateValidationError(validationErrors, "nameSuffix")
      initialBidValidationErrors = this.generateValidationError(validationErrors, "initialBid")
      initialBudgetValidationErrors = this.generateValidationError(validationErrors, "initialBudget")
      duplicateTargetingGroupsErrors = this.generateValidationError(validationErrors, "duplicateTargetingGroups")
      bidTypeValidationErrors = this.generateValidationError(validationErrors, "bidType")
    }

    if (this.props.campaignTargetingGroup.isOpen) {
      return (
        <>
          <ProviderAccountDropdown
            campaignTargetingGroup={this.props.campaignTargetingGroup}
            providerAccountValidationErrors={providerAccountValidationErrors}
            groupIndex={this.props.groupIndex}
          />
          {this.props.selectedSourceKey === "Taboola" ? (
            <DeviceTargetingControl
              campaignTargetingGroup={this.props.campaignTargetingGroup}
              groupIndex={this.props.groupIndex}
              deviceTargetingValidationErrors={deviceTargetingValidationErrors}
            />
          ) : null}
          {!this.props.campaignTargetingGroup.isDeviceTargetingEnabled ? (
            <>
              <PlatformSelection
                campaignTargetingGroup={this.props.campaignTargetingGroup}
                platformsValidationErrors={platformsValidationErrors}
                groupIndex={this.props.groupIndex}
              />

              {this.props.selectedSourceKey === "Taboola" ? (
                <BrowsersDropdown
                  campaignTargetingGroup={this.props.campaignTargetingGroup}
                  groupIndex={this.props.groupIndex}
                />
              ) : null}
            </>
          ) : null}
          <CountriesAndCountryGroupsDropdown
            campaignTargetingGroup={this.props.campaignTargetingGroup}
            groupIndex={this.props.groupIndex}
          />
          <CampaignTypeDropdown
            campaignTargetingGroup={this.props.campaignTargetingGroup}
            groupIndex={this.props.groupIndex}
            handleBidTypeSelection={this.handleBidTypeSelection}
            bidTypeValidationErrors={bidTypeValidationErrors}
            isPreset={false}
          />
          {![BidTypes.roas.value, BidTypes.highest_value.value].includes(this.state.bidType) && (
            <ConversionEventsDropdown
              isRsoc={this.props.isRsoc}
              conversionEvents={this.props.conversionEvents}
              conversionEventsValidationErrors={conversionEventsValidationErrors}
              campaignTargetingGroup={this.props.campaignTargetingGroup}
              groupIndex={this.props.groupIndex}
            />
          )}

          {this.props.selectedSourceKey === "Facebook" ? (
            <>
              <PlacementsDropdown
                campaignTargetingGroup={this.props.campaignTargetingGroup}
                groupIndex={this.props.groupIndex}
                allowSelectAutomatic={true}
              />
            </>
          ) : null}
          <InitialBidAndBudget
            conversionEvents={this.props.conversionEvents}
            bidType={this.state.bidType}
            campaignTargetingGroup={this.props.campaignTargetingGroup}
            groupIndex={this.props.groupIndex}
            initialBidValidationErrors={initialBidValidationErrors}
            initialBudgetValidationErrors={initialBudgetValidationErrors}
          />
          <StatusSwitch campaignTargetingGroup={this.props.campaignTargetingGroup} groupIndex={this.props.groupIndex} />
          <TagsDropdown campaignTargetingGroup={this.props.campaignTargetingGroup} groupIndex={this.props.groupIndex} />
          <NameSuffix
            campaignTargetingGroup={this.props.campaignTargetingGroup}
            nameSuffixValidationErrors={nameSuffixValidationErrors}
            groupIndex={this.props.groupIndex}
          />
          {duplicateTargetingGroupsErrors}
        </>
      )
    } else {
      let fieldsToValuesMap = new Map()
      let targetingGroup = this.props.campaignTargetingGroup
      let selectedDeviceTargetingGroup = targetingGroup.selectedDeviceTargetingGroup
      let selectedCountriesAndGroups = [
        ...this.props.campaignTargetingGroup.selectedCountries,
        ...this.props.campaignTargetingGroup.selectedCountryGroups.map((countryGroup) => countryGroup.name),
      ]

      fieldsToValuesMap.set(
        "Provider account",
        targetingGroup.providerAccount ? targetingGroup.providerAccount.name : ""
      )

      fieldsToValuesMap.set(
        "Bid Type",
        targetingGroup.campaignTypes
          .filter((campaignType) => campaignType.selected)
          .map((selectedCampaignType) => selectedCampaignType.name)
          .join(", ")
      )
      if (selectedDeviceTargetingGroup) {
        fieldsToValuesMap.set("Device Targeting Group", selectedDeviceTargetingGroup.name)
      } else {
        fieldsToValuesMap.set("Device", this.getSelectedPlatformsSummaryText())
        if (this.props.selectedSourceKey === "Taboola") {
          fieldsToValuesMap.set(
            "Browsers",
            targetingGroup.browsers
              .filter((browser) => browser.selected)
              .map((browser) => browser.name)
              .join(", ")
          )
        }
      }
      fieldsToValuesMap.set("Country", selectedCountriesAndGroups.join(", "))

      if (this.props.selectedSourceKey === "Facebook") {
        fieldsToValuesMap.set(
          "Placements",
          targetingGroup.selectedPlacements
            ? targetingGroup.selectedPlacements.map((selectedPlacement) => selectedPlacement.name)
            : ""
        )
      }
      fieldsToValuesMap.set(
        "Conversion Event",
        targetingGroup.selectedConversionEvents.length > 0
          ? targetingGroup.selectedConversionEvents
              .map((selectedConversionEvent) => selectedConversionEvent.name)
              .join(", ")
          : ""
      )

      fieldsToValuesMap.set("initial bid", targetingGroup.initialBid)
      fieldsToValuesMap.set("initial budget", targetingGroup.initialBudget)
      fieldsToValuesMap.set("Status on creation", targetingGroup.isActive ? "Active" : "Paused")

      fieldsToValuesMap.set(
        "Tags",
        targetingGroup.selectedTags.length > 0
          ? targetingGroup.selectedTags.map((selectedTag) => selectedTag.name).join(", ")
          : ""
      )
      fieldsToValuesMap.set("Name suffix", targetingGroup.nameSuffix)

      let groupValidationErrorsIndicator

      if (validationErrors && validationErrors.size > 0 && this.props.validationErrorsVisible) {
        groupValidationErrorsIndicator = <div className="validation-error">Errors exist for this targeting group</div>
      }

      let summaryFields = []
      let notSelectedField = ""
      fieldsToValuesMap.forEach((value, key) => {
        summaryFields.push(
          <div className="summary-field" key={key}>
            <span className="summary-field-title">{`${key}: `}</span>
            <span className="summary-field-value">{value ? value : notSelectedField}</span>
          </div>
        )
      })

      return (
        <div className="group-summary">
          {groupValidationErrorsIndicator}
          {summaryFields}
        </div>
      )
    }
  }

  openTargetingGroupPresetPopup = (event) => {
    this.closeGroupOptionsMenu(event)

    let targetingGroupDataForPreset = {
      provider_id: this.props.selectedSource.id,
      targeting_json: JSON.stringify(this.props.campaignTargetingGroup),
    }

    this.setState({
      targetingGroupPresetDialogOpen: true,
      targetingGroupDataForPreset,
    })
  }

  closeTargetingGroupPresetPopup = () => {
    this.setState({
      targetingGroupPresetDialogOpen: false,
    })
  }

  renderTargetingGroupPresetPopup = () => {
    return (
      <TargetingGroupPresetPopup
        dialogOpen={this.state.targetingGroupPresetDialogOpen}
        isEdit={false}
        existingTargetingGroupPresetData={this.state.targetingGroupDataForPreset}
        networkId={this.props.networkId}
        networkCode={this.props.networkCode}
        closeDialog={this.closeTargetingGroupPresetPopup}
      />
    )
  }

  updateConversionEventsList = (conversionEventsData) => {
    const targetingGroupData = this.props.campaignTargetingGroups[this.props.groupIndex]
    const defaultConversionEvents = this.props.conversionEvents.map((conversionEvent) => ({
      name: conversionEvent.conversion_name,
      value: conversionEvent.conversion_value,
    }))
    const newTargetingGroupData = Object.assign({}, targetingGroupData, {
      selectedConversionEvents: conversionEventsData || defaultConversionEvents,
    })
    this.props.dispatch(updateTargetingGroup(this.props.groupIndex, newTargetingGroupData))
  }

  render() {
    return (
      <div
        className={"campaign-targeting-group " + (this.props.campaignTargetingGroup.isOpen ? "open" : "clickable")}
        key={this.props.groupIndex}
        onClick={(event) =>
          this.props.campaignTargetingGroup.isOpen
            ? {}
            : this.props.dispatch(toggleTargetingGroupOpen(event, this.props.groupIndex))
        }
      >
        <div
          className="group-title clickable"
          onClick={(event) => this.props.dispatch(toggleTargetingGroupOpen(event, this.props.groupIndex))}
        >
          <span>
            {this.renderGroupOpenSwitch()}
            {`Group ${this.props.groupIndex + 1}`}
          </span>
          {this.renderGroupOptionsMenu()}
        </div>
        <div className="group-fields">{this.renderGroupFields()}</div>
        {this.renderTargetingGroupPresetPopup()}
      </div>
    )
  }
}

function filteredConversionEvents(conversionEventsValue, selectedSource, isRsoc) {
  let conversionEvents = { ...conversionEventsValue[selectedSource.id] }
  // PP-3095 - pi:SIGNUP convertion support rsoc and non-rsoc (VV>7 and RSP)
  if (!isRsoc && selectedSource.id === CampaignsConsts.PINTEREST_PROVIDER_ID) {
    return Object.values(conversionEvents)
  }
  const filteredConversionEvents = Object.values(conversionEvents).filter((conversionEvent) => {
    return isRsoc ? conversionEvent.is_rsoc : !conversionEvent.is_rsoc
  })
  return filteredConversionEvents
}

function mapStateToProps(state, ownProps) {
  let selectedSourceKey = null
  let selectedSource = null
  let isRsoc = state.campaignCreationWizard.campaignSettings.isRsoc
  Object.keys(state.campaignCreationWizard.campaignSources.sources).forEach((sourceKey) => {
    if (state.campaignCreationWizard.campaignSources.sources[sourceKey].selected) {
      selectedSourceKey = sourceKey
      selectedSource = state.campaignCreationWizard.campaignSources.sources[sourceKey]
    }
  })
  const campaignTargetingGroups = state.campaignCreationWizard.campaignTargetingGroups
  return {
    selectedSourceKey,
    selectedSource,
    isRsoc,
    networkId: state.campaignCreationWizard.campaignSettings.networkId,
    networkCode: state.campaignCreationWizard.campaignSettings.networkCode,
    parentCampaign: state.campaignCreationWizard.parentCampaign,
    wasDuplicateReviewWarningShown: state.campaignCreationWizard.wasDuplicateReviewWarningShown,
    campaignTargetingGroups,
    conversionEvents: filteredConversionEvents(state.app.conversionEvents, selectedSource, isRsoc),
  }
}

export default connect(mapStateToProps)(CampaignCreationTargetingGroup)
