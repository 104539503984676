import merge from "deepmerge"
import CampaignCreationService from "../api/campaignCreationService"
import CountryService from "../api/countryService"
import CampaignsCreationConsts from "./campaignCreationConsts"
import { campaignTargetingGroup, generateCreativeObject } from "./campaignCreationReducer"
import LanguageService from "../api/languageService"
import ProviderAccountService from "../api/providerAccountService"
import RequestsService from "../api/requestsService"
import CampaignCreationActionTypes from "./campaignCreationActionTypes"
import axios from "axios"
import ActionTypes from "../common/actions/actionTypes"
import CampaignsCreationSourcesInitialState from "./campaignCreationSourcesInitialState"
import CampaignServiceV2 from "../api/campaignServiceV2"
import CampaignsConsts from "../campaignsV2/campaignsConsts"

export function openCampaignCreationWizard(isLoading = false) {
  return { type: CampaignCreationActionTypes.OPEN_CAMPAIGN_CREATION_WIZARD, isOpen: true, isLoading }
}

export function openCampaignDuplicationWizard(campaign) {
  return (dispatch) => {
    dispatch({
      type: CampaignCreationActionTypes.OPEN_CAMPAIGN_CREATION_WIZARD,
      isOpen: true,
      duplication: true,
      campaign,
      isLoading: false,
    })
  }
}

export function closeCampaignCreationWizard(showWarningDialog) {
  return (dispatch) => {
    RequestsService.cancelRequest("site-providers-request")
    RequestsService.cancelRequest("campaign-creatives-request")
    RequestsService.cancelRequest("root-campaign-request")
    dispatch({ type: CampaignCreationActionTypes.CLOSE_CAMPAIGN_CREATION_WIZARD, isOpen: false, showWarningDialog })
  }
}

export function changeWizardStep(stepNum) {
  return { type: CampaignCreationActionTypes.CHANGE_WIZARD_STEP, stepNum }
}

export function addCreativeAction(groupIndex = null) {
  return { type: CampaignCreationActionTypes.ADD_CREATIVE, groupIndex }
}

export function updateThumbnailAsync(id, file, groupIndex = null) {
  return (dispatch) => {
    return CampaignCreationService.getValidThumbnailUrl(file.name, file.type).then((signedUrlResult) => {
      return CampaignCreationService.uploadCreativeToS3(signedUrlResult.data, file).then((result) => {
        dispatch({
          type: CampaignCreationActionTypes.UPDATE_THUMBNAIL_RESPONSE,
          id,
          url: result.request.responseURL.split("?")[0],
          isVideo: file.type.includes("video"),
          groupIndex,
        })
      })
    })
  }
}

export function updateDynamicThumbnailAsync(id, file, thumbnailTypeId, groupIndex = null) {
  return (dispatch) => {
    return CampaignCreationService.getValidThumbnailUrl(file.name, file.type).then((signedUrlResult) => {
      return CampaignCreationService.uploadCreativeToS3(signedUrlResult.data, file).then((result) => {
        let actionType = CampaignCreationActionTypes.UPDATE_DYNAMIC_THUMBNAIL_RESPONSE
        dispatch({
          type: actionType,
          id,
          thumbnailTypeId,
          url: result.request.responseURL.split("?")[0],
          isVideo: file.type.includes("video"),
          groupIndex,
        })
      })
    })
  }
}

export function onImagesUploaded(file, thumbnailTypeId, selectedSource, ids, groupIndex) {
  return (dispatch) => {
    return CampaignCreationService.getValidThumbnailUrl(file.name, file.type).then((signedUrlResult) => {
      return CampaignCreationService.uploadCreativeToS3(signedUrlResult.data, file).then((result) => {
        let originalThumbnail = result.request.responseURL.split("?")[0]
        if (CampaignsConsts.GOOGLE_PROVIDER_ID === selectedSource.id) {
          return CampaignCreationService.fetchCreativeThumbnailWithCrops(
            originalThumbnail,
            selectedSource.cropDetails
          ).then((result) => {
            result.data.forEach((image, index) => {
              dispatch({
                type: CampaignCreationActionTypes.UPDATE_THUMBNAIL_PER_RATIO_RESPONSE,
                id: ids[index],
                thumbnailTypeId,
                url: image.thumbnail,
                originalThumbnail,
                cropDetails: image.crop_details,
                selectedRatio: image.ratio,
                groupIndex,
                shouldChangeMainThumbnail: true,
              })
            })
          })
        }
      })
    })
  }
}

export function updateThumbnailPerSourceAsync(id, sourceKey, file, cropDetails, selectedRatio, groupIndex = null) {
  return (dispatch) => {
    return CampaignCreationService.getValidThumbnailUrl(file.name, file.type).then((signedUrlResult) => {
      return CampaignCreationService.uploadCreativeToS3(signedUrlResult.data, file).then((result) => {
        dispatch({
          type: CampaignCreationActionTypes.UPDATE_THUMBNAIL_PER_SOURCE_RESPONSE,
          id,
          url: result.request.responseURL.split("?")[0],
          sourceKey,
          cropDetails,
          selectedRatio,
          groupIndex,
        })
      })
    })
  }
}

export function updateThumbnailPerRatioAsync(id, file, thumbnailTypeId, cropDetails, selectedRatio, groupIndex = null) {
  return (dispatch) => {
    return CampaignCreationService.getValidThumbnailUrl(file.name, file.type).then((signedUrlResult) => {
      return CampaignCreationService.uploadCreativeToS3(signedUrlResult.data, file).then((result) => {
        dispatch({
          type: CampaignCreationActionTypes.UPDATE_THUMBNAIL_PER_RATIO_RESPONSE,
          id,
          thumbnailTypeId,
          url: result.request.responseURL.split("?")[0],
          cropDetails,
          selectedRatio,
          groupIndex,
          shouldChangeMainThumbnail: false,
        })
      })
    })
  }
}

export function updateThumbnailWithUrl(id, fileUrl, isVideo, groupIndex = null) {
  return { type: CampaignCreationActionTypes.UPDATE_THUMBNAIL_RESPONSE, id, url: fileUrl, isVideo, groupIndex }
}

export function updateThumbnailLoadingStatus(id, isLoading, groupIndex = null) {
  return { type: CampaignCreationActionTypes.UPDATE_THUMBNAIL_IS_LOADING, id, isLoading, groupIndex }
}

export function updateDynamicThumbnailLoadingStatus(id, isLoading, thumbnailTypeId, groupIndex = null) {
  return {
    type: CampaignCreationActionTypes.UPDATE_DYNAMIC_THUMBNAIL_IS_LOADING,
    id,
    isLoading,
    thumbnailTypeId,
    groupIndex,
  }
}

export function updateDynamicGroupsThumbnailLoadingStatus(id, isLoading, thumbnailTypeId, groupIndex = null) {
  return {
    type: CampaignCreationActionTypes.UPDATE_DYNAMIC_THUMBNAIL_IS_LOADING,
    id,
    isLoading,
    thumbnailTypeId,
    groupIndex,
  }
}

export function getCreativesAsync(campaign) {
  return (dispatch) => {
    dispatch({ type: CampaignCreationActionTypes.GET_CREATIVES })

    return CampaignCreationService.getCreatives(campaign)
      .then((response) => {
        dispatch({ type: CampaignCreationActionTypes.GET_CREATIVES_RESPONSE, creatives: response.data, campaign })
      })
      .catch((error) => {
        if (!error) {
          // The request was cancelled
        }
      })
  }
}

export function validateThumbnail(id, thumbnailErrors, groupIndex = null) {
  return { type: CampaignCreationActionTypes.VALIDATE_THUMBNAIL, id, thumbnailErrors, groupIndex }
}

export function validateDynamicThumbnail(id, thumbnailErrors, thumbnailTypeId, groupIndex = null) {
  return {
    type: CampaignCreationActionTypes.VALIDATE_DYNAMIC_THUMBNAIL,
    id,
    thumbnailErrors,
    thumbnailTypeId,
    groupIndex,
  }
}

export function validateDynamicGroupsThumbnail(id, thumbnailErrors, thumbnailTypeId, groupIndex) {
  return {
    type: CampaignCreationActionTypes.VALIDATE_DYNAMIC_THUMBNAIL,
    id,
    thumbnailErrors,
    thumbnailTypeId,
    groupIndex,
  }
}
export function removeThumbnail(id, groupIndex) {
  return { type: CampaignCreationActionTypes.REMOVE_THUMBNAIL, id, groupIndex }
}

export function removeDynamicThumbnail(id, thumbnailTypeId, groupIndex = null) {
  return { type: CampaignCreationActionTypes.REMOVE_DYNAMIC_THUMBNAIL, id, thumbnailTypeId, groupIndex }
}

export function removeDynamicGroupsThumbnail(id, thumbnailTypeId, groupIndex) {
  return { type: CampaignCreationActionTypes.REMOVE_DYNAMIC_THUMBNAIL, id, thumbnailTypeId, groupIndex }
}

export function updateCreative(id, text, description, headline, callToAction, groupIndex) {
  return {
    type: CampaignCreationActionTypes.UPDATE_CREATIVE,
    id,
    text,
    description,
    headline,
    callToAction,
    groupIndex: groupIndex,
  }
}

export function updateDynamicCreative(id, fieldType, text, groupIndex = null) {
  return { type: CampaignCreationActionTypes.UPDATE_DYNAMIC_CREATIVE, id, fieldType, text, groupIndex }
}

export function updateDynamicCreativeGroups(id, fieldType, text, groupIndex) {
  return { type: CampaignCreationActionTypes.UPDATE_DYNAMIC_CREATIVE, id, fieldType, text, groupIndex }
}

export function removeCreative(id, groupIndex) {
  return { type: CampaignCreationActionTypes.REMOVE_CREATIVE, id, groupIndex }
}

export function cloneCreative(id, groupIndex) {
  return { type: CampaignCreationActionTypes.CLONE_CREATIVE, id, groupIndex }
}

export function updateCampaignStatus(isActive) {
  return { type: CampaignCreationActionTypes.UPDATE_STATUS, isActive }
}

export function updateCampaignDetails(
  name,
  url,
  siteId,
  siteDomain,
  possibleSitesForDomain,
  networkId,
  networkCode,
  selectedLanguages,
  isRsoc,
  selectedKeywords,
  articleIsArchived,
  articleKeywords
) {
  return {
    type: CampaignCreationActionTypes.UPDATE_CAMPAIGN_DETAILS,
    name,
    url,
    siteId,
    siteDomain,
    possibleSitesForDomain,
    networkId,
    networkCode,
    selectedLanguages,
    isRsoc,
    selectedKeywords,
    articleIsArchived,
    articleKeywords,
  }
}

export function updateSource(sourceKey, sourceData) {
  return { type: CampaignCreationActionTypes.UPDATE_SOURCE, sourceKey, sourceData }
}

export function resetTargetingGroups() {
  return { type: CampaignCreationActionTypes.RESET_TARGETING_GROUPS }
}

export function updateTargetingGroup(groupIndex, targetingGroupData) {
  return { type: CampaignCreationActionTypes.UPDATE_TARGETING_GROUP, groupIndex, targetingGroupData }
}

export function toggleTargetingGroupOpen(event, groupIndex) {
  event.stopPropagation()

  return { type: CampaignCreationActionTypes.TOGGLE_TARGETING_GROUP_OPEN, groupIndex }
}

export function toggleCreativesGroupOpen(event, groupIndex) {
  event.stopPropagation()

  return { type: CampaignCreationActionTypes.TOGGLE_CREATIVES_GROUP_OPEN, groupIndex }
}

export function addTargetingGroup(selectedSourceKey, targetingGroupPreset = null) {
  return { type: CampaignCreationActionTypes.ADD_TARGETING_GROUP, selectedSourceKey, targetingGroupPreset }
}

export function addCreativesGroup() {
  return { type: CampaignCreationActionTypes.ADD_CREATIVES_GROUP }
}

export function removeTargetingGroup(groupIndex) {
  return { type: CampaignCreationActionTypes.REMOVE_TARGETING_GROUP, groupIndex }
}

export function duplicateTargetingGroup(groupIndex, targetingGroupData) {
  return { type: CampaignCreationActionTypes.DUPLICATE_TARGETING_GROUP, groupIndex, targetingGroupData }
}

export function removeCreativesGroup(groupIndex) {
  return { type: CampaignCreationActionTypes.REMOVE_CREATIVES_GROUP, groupIndex }
}

export function duplicateCreativesGroup(groupIndex, creativesGroupData) {
  return { type: CampaignCreationActionTypes.DUPLICATE_CREATIVES_GROUP, groupIndex, creativesGroupData }
}

export function launchingCampaigns() {
  return { type: CampaignCreationActionTypes.LAUNCHING_CAMPAIGNS }
}

export function launchCampaignCreationAsync(campaignCreationRequests) {
  window.Intercom("trackEvent", "campaign-creation-wizard-completed")
  return (dispatch) => {
    // Showing a fake indicator as if we're "launching" the campaigns
    dispatch(launchingCampaigns())
    setTimeout(() => {
      dispatch(closeCampaignCreationWizard(false))
    }, 2000)

    // Meanwhile, we're actually doing the request (that could take a lot more than 2 seconds)
    return CampaignCreationService.launchCampaignsCreation(campaignCreationRequests)
  }
}

export function fetchRecentCountriesAsync() {
  return (dispatch) => {
    CountryService.getRecentCountries().then((response) => {
      dispatch({ type: CampaignCreationActionTypes.UPDATE_RECENT_COUNTRIES, recentCountries: response.data })
    })
  }
}

export function fetchRecentLanguagesAsync() {
  return (dispatch) => {
    LanguageService.getRecentLanguages().then((response) => {
      dispatch({ type: CampaignCreationActionTypes.UPDATE_RECENT_LANGUAGES, recentLanguages: response.data })
    })
  }
}

export function increaseRecentCountriesAsync(countriesIds) {
  return (dispatch) => {
    CountryService.increaseRecentCountries(countriesIds)
  }
}

export function increaseRecentLanguagesAsync(selectedLanguages) {
  return (dispatch) => {
    LanguageService.increaseRecentLanguages(selectedLanguages)
  }
}

// this action is like duplicate but not from existing account but from existing creation request.
export function openWizardFromCreationRequest(campaignCreationState) {
  // Re-generating creative object so that all creatives will have the creative valid fields, important for old
  // creation states that don't have newer fields like croppedImagesPerSource.
  // Also, clearing existing croppedImagesPerSource because these were only relevant for the original creation
  // (We're duplicating for one specific source, that source already has the cropped image under creative.thumbnail)
  let creatives = campaignCreationState.creatives
  creatives = creatives.map((creative) => {
    return generateCreativeObject(creative)
  })

  let creativesToAdd = { creatives }

  let sources = merge({}, CampaignsCreationSourcesInitialState.CAMPAIGN_CREATION_SOURCES)

  let providerKey
  Object.keys(sources).forEach((sourceKey) => {
    if (parseInt(campaignCreationState.provider_id) === sources[sourceKey].id) {
      sources[sourceKey].selected = true
      providerKey = sourceKey
    }
  })

  let targetingGroup = merge({}, campaignTargetingGroup)
  targetingGroup = { ...targetingGroup, ...merge({}, sources[providerKey]) }

  if (targetingGroup.isCreativesGroup) {
    creativesToAdd = { creativesGroups: [{ isOpen: true, creatives }] }
  }

  if (Array.isArray(campaignCreationState.countries) && campaignCreationState.countries.length > 1) {
    // If the array of country_code has more than one item, we will try to infer the country group.
    // If we are not able to, put the list of country codes as the selected countries
    if (campaignCreationState.country_group_id) {
      targetingGroup.selectedCountryGroups = [
        {
          id: campaignCreationState.country_group_id,
          name: campaignCreationState.country_group,
          selected: true,
        },
      ]
    } else {
      targetingGroup.selectedCountries = campaignCreationState.countries
    }
  } else {
    targetingGroup.selectedCountries = campaignCreationState.countries
  }

  if (campaignCreationState.device_targeting_group_id) {
    targetingGroup.selectedDeviceTargetingGroup = [
      {
        value: campaignCreationState.device_targeting_group_id,
        name: campaignCreationState.device_targeting_group_name,
      },
    ]
    targetingGroup.isDeviceTargetingEnabled = true
  }
  targetingGroup.selectedTags = campaignCreationState.tags
    ? campaignCreationState.tags.map((tag) => {
        return { name: tag }
      })
    : []

  let campaignTargetingGroups = [targetingGroup]

  let wizardState = {
    isOpen: true,
    isLoading: false,
    isLaunching: false,
    currentStep: CampaignsCreationConsts.CREATION_WIZARD_STEPS.sources,
    isDuplication: true,
    campaignSettings: {
      siteId: campaignCreationState.site_id,
      name: campaignCreationState.original_name,
      articleUrl: campaignCreationState.url,
      selectedLanguages: [campaignCreationState.language_code.toUpperCase()],
    },
    campaignCreatives: {
      isFetchingCreatives: false,
      creativeIdCounter: Math.max(...campaignCreationState.creatives.map((creative) => creative.id)),
      ...creativesToAdd,
    },
    campaignSources: {
      sources,
    },
    campaignTargetingGroups,
  }

  return { type: CampaignCreationActionTypes.OPEN_WIZARD_WITH_STATE, wizardState }
}

export function updateSiteProviderAccounts(siteId, providerAccounts) {
  return { type: CampaignCreationActionTypes.UPDATE_SITE_PROVIDER_ACCOUNTS, siteId, providerAccounts }
}

export function resetSiteProviderAccounts() {
  return { type: CampaignCreationActionTypes.RESET_SITE_PROVIDER_ACCOUNTS }
}

export function getSiteProviderAccounts(siteId) {
  return (dispatch) => {
    dispatch(resetSiteProviderAccounts())
    ProviderAccountService.getSiteProviderAccounts(siteId)
      .then((response) => {
        dispatch(updateSiteProviderAccounts(siteId, response.data.provider_accounts))
      })
      .catch((error) => {
        if (!error) {
          // The request was cancelled
        }
      })
  }
}

export function getRelevantCreationSettingsForSite(siteId, network) {
  return (dispatch) => {
    RequestsService.cancelRequest("site-providers-request")
    RequestsService.cancelRequest("get-network-tags")
    dispatch(resetSiteProviderAccounts())

    return axios
      .all([ProviderAccountService.getSiteProviderAccounts(siteId), CampaignServiceV2.getNetworkTags(network.code)])
      .then(
        axios.spread((providerAccountsResponse, networkTagsResponse) => {
          dispatch(updateSiteProviderAccounts(siteId, providerAccountsResponse.data.provider_accounts))
          dispatch({ type: ActionTypes.NETWORK_TAGS_RESPONSE, networkTags: networkTagsResponse.data.network_tags })
          return { providerAccountsResponse, networkTagsResponse }
        })
      )
      .catch((error) => {
        if (!error) {
          // The request was cancelled
        }
      })
  }
}

export function updateSelectedTags(selectedTags) {
  return { type: CampaignCreationActionTypes.UPDATE_SELECTED_TAGS, selectedTags }
}

export function updateDuplicateReviewWarningState() {
  return { type: CampaignCreationActionTypes.UPDATE_DUPLICATE_REVIEW_WARNING_STATE }
}

export function loadThumbnails(adTypeId) {
  return { type: CampaignCreationActionTypes.LOAD_THUMBNAILS, adTypeId }
}

export function updateCropDetails(adTypeId, sourceKey) {
  return { type: CampaignCreationActionTypes.UPDATE_CROP_DETAILS, adTypeId, sourceKey }
}
