import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Switch } from "@material-ui/core"
import GenericDropdown from "../../../../common/components/genericDropdown"
import { updateTargetingGroup } from "../../../campaignCreationActions"
import CampaignsConsts from "../../../../campaignsV2/campaignsConsts"

const DeviceTargetingControl = ({
  campaignTargetingGroup,
  deviceTargetingGroups = [],
  onChange,
  dispatch,
  groupIndex,
  deviceTargetingValidationErrors,
}) => {
  const [selectedIndexesArr, setSelectedIndexesArr] = useState([])
  const [menuItems, setMenuItems] = useState(
    Object.values(deviceTargetingGroups[CampaignsConsts.TABOOLA_PROVIDER_ID] || {}).map((group) => ({
      name: group.name,
      value: group.id,
    }))
  )
  const [menuOpen, setMenuOpen] = useState(false)
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)

  const handleSwitchChange = (event) => {
    const newStatus = event.target.checked
    setSelectedIndexesArr([])

    const updatedValues = {
      isDeviceTargetingEnabled: newStatus,
      selectedDeviceTargetingGroup: null, // Reset when turned off
    }

    if (onChange) {
      onChange(updatedValues)
    } else {
      dispatch(updateTargetingGroup(groupIndex, updatedValues))
    }
  }

  const handleMenuOpen = (event) => {
    setMenuOpen(true)
    setMenuAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setMenuOpen(false)
  }

  const handleDropdownChange = (selectedIndexes) => {
    let selectedGroups = []

    menuItems.forEach((group, index) => {
      if (selectedIndexes.includes(index)) {
        selectedGroups.push(group)
      }
    })

    setSelectedIndexesArr(selectedIndexes)

    const updatedValues = {
      selectedDeviceTargetingGroup: selectedGroups.length === 1 ? selectedGroups[0] : selectedGroups,
    }

    if (onChange) {
      onChange(updatedValues)
    } else {
      dispatch(updateTargetingGroup(groupIndex, updatedValues))
    }
    handleMenuClose()
  }

  return (
    <div className="device-targeting-group">
      <div className="status-field">
        <div className="status-label">Device Targeting</div>
        <div className="status-switch">
          <Switch
            data-class="tooltip-custom type-icon-info"
            classes={{
              root: "switch",
              switchBase: "button-base",
              checked: "green",
              icon: "icon",
              bar: "bar",
            }}
            checked={campaignTargetingGroup.isDeviceTargetingEnabled}
            onChange={handleSwitchChange}
          />
          <span className="device-targeting-text">
            {campaignTargetingGroup.isDeviceTargetingEnabled ? "Use targeting groups" : "Manually select targeting"}
          </span>
        </div>
        {deviceTargetingValidationErrors}
      </div>

      {campaignTargetingGroup.isDeviceTargetingEnabled && (
        <div className="text-field device-targeting-group-selection">
          <div className="creation-generic-dropdown-label">Targeting Group</div>
          <GenericDropdown
            header={
              campaignTargetingGroup.selectedDeviceTargetingGroup
                ? campaignTargetingGroup.selectedDeviceTargetingGroup.name
                : "Select Group"
            }
            selectOptionCallback={handleDropdownChange}
            openDropdownCallback={handleMenuOpen}
            closeDropdownCallback={handleMenuClose}
            allowSelectAll={false}
            options={menuItems}
            open={menuOpen}
            anchorEl={menuAnchorEl}
            selectedIndexes={selectedIndexesArr}
            allowMultiple={false}
            allowAutoSuggest={true}
            showEmptyOption={false}
            allowNoSelection={false}
            buttonClassName="creation-generic-dropdown-button"
            menuClassName="creation-generic-dropdown-menu"
          />
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  deviceTargetingGroups: state.app.deviceTargetingGroups || [],
})

export default connect(mapStateToProps)(DeviceTargetingControl)
