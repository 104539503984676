import React from "react"
import { connect } from "react-redux"
import grey from "@material-ui/core/colors/grey"
import { Chip, CircularProgress, Divider } from "@material-ui/core"
import * as Moment from "moment"
import reactStringReplace from "react-string-replace"
import PlatformTypes from "../../common/consts/platformTypes"
import ActivityOptionsMenu from "./components/activityOptionsMenu"
import {
  dismissAllActivities,
  navigationDrawerStateChange,
  retryMultipleActivities,
  showMoreActivitiesAsync,
} from "../navigationBarActions"
import NoData from "./noData"
import ActivityStatus from "../../common/consts/activityStatus"
import SuccessActivity from "../../resources/svgs/SuccessActivity.svg"
import FailedActivity from "../../resources/svgs/FailedActivity.svg"
import { isNullOrEmpty, isNullOrUndefined } from "../../utils/funcUtils"
import Consts from "../../app/consts"
import { getCountryText } from "../../utils/countryUtils"
import CampaignsConsts from "../../campaignsV2/campaignsConsts"
import { disableInfiniteScrollHandler, getMoreElements, handleInfiniteScroll } from "../../utils/domUtils"
import CampaignPopupLink from "../../campaignPopupV2/campaignPopupLink"
import { getProviderCode } from "../../utils/providerUtils"
import BidTypes from "../../common/consts/bidTypes"

class ActivitiesDrawer extends React.Component {
  ACTIVITY_STATUSES_WITH_OPTIONS_MENU = [ActivityStatus.FAILED, ActivityStatus.DISMISSED, ActivityStatus.PENDING]
  CAMPAIGN_CREATION_ACTIVITY_TYPE = 2
  PROVIDERS_WITH_PARTIAL_CREATION_RETRY = [CampaignsConsts.TABOOLA_PROVIDER_ID, CampaignsConsts.FACEBOOK_PROVIDER_ID]

  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
    }

    this.importantActivities = null
    this.otherActivities = null
    this.activityToIcon = new Map()
    this.componentName = "activities"

    this.activityToIcon[ActivityStatus.SUCCESS] = <SuccessActivity />
    this.activityToIcon[ActivityStatus.FAILED] = <FailedActivity />
    this.activityToIcon[ActivityStatus.DISMISSED] = <FailedActivity />
    this.activityToIcon[ActivityStatus.PENDING] = (
      <CircularProgress size={9} thickness={9} style={{ color: grey[500] }} className="loader icon" />
    )
  }

  componentWillMount() {
    if (this.props.activities.important !== null || this.props.activities.other !== null) {
      if (this.props.activities.important) {
        this.importantActivities = this.createActivitiesComponent(this.props.activities.important, true)
      }
      if (this.props.activities.other) {
        this.otherActivities = this.createActivitiesComponent(this.props.activities.other, false)
      }
      this.setState({
        isLoading: false,
      })
    } else {
      this.setState({
        isLoading: false,
      })
    }
  }

  componentDidMount() {
    handleInfiniteScroll(".drawer", this.getMoreActivities)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activities.important !== null || nextProps.activities.other !== null) {
      if (nextProps.activities.important) {
        this.importantActivities = this.createActivitiesComponent(nextProps.activities.important, true)
      }
      if (nextProps.activities.other) {
        this.otherActivities = this.createActivitiesComponent(nextProps.activities.other, false)
      }
      this.setState({
        isLoading: false,
      })
    } else {
      this.setState({
        isLoading: false,
      })
    }
  }

  componentWillUnmount() {
    disableInfiniteScrollHandler(".drawer", this.getMoreActivities)
  }

  closeActivitiesDrawer = () => {
    this.props.dispatch(navigationDrawerStateChange(false, this.componentName))
  }

  getCampaignTypeTag = (campaignType) => {
    if (!isNullOrEmpty(campaignType)) {
      return <span>{BidTypes.idToType[campaignType].name}</span>
    }

    return null
  }

  getNetworkTag = (siteId) => {
    if (this.props.siteIdToNetwork.get(siteId)) {
      return <span>{this.props.siteIdToNetwork.get(siteId).name}</span>
    }

    return null
  }

  getActivityContent = (activityData) => {
    let content = activityData.message.content
    let campaign = activityData.campaign_data

    Object.keys(activityData.message.parameters).forEach((key, index) => {
      let param = "[" + key + "]"

      if (key.indexOf("campaign_name") > -1) {
        let disableClick = true

        if (!isNullOrUndefined(campaign)) {
          campaign.campaign_id = isNullOrUndefined(campaign.campaign_id)
            ? !isNullOrEmpty(campaign.external_id) & !isNullOrEmpty(campaign.provider_id)
              ? `${getProviderCode(campaign.provider_id)}:${campaign.external_id}`
              : null
            : campaign.campaign_id

          disableClick = [campaign.campaign_id].some((campaignKey) => {
            return campaignKey === null
          })
        }

        content = reactStringReplace(content, param, (match, i) => {
          if (disableClick) {
            return (
              <span key={key} className="parameter disabled">
                {activityData.message.parameters[key]}
              </span>
            )
          }

          return (
            <CampaignPopupLink
              key={key}
              campaign={campaign}
              className="fake-link parameter"
              onClick={this.closeActivitiesDrawer}
            >
              {activityData.message.parameters[key]}
            </CampaignPopupLink>
          )
        })
      } else if (key.indexOf("provider_id") > -1) {
        content = reactStringReplace(content, param, (match, i) => (
          <span key={key} className="parameter">
            {this.props.providerIdToProvider.get(Number(activityData.message.parameters[key])).name}
          </span>
        ))
      } else {
        content = reactStringReplace(content, param, (match, i) => (
          <span key={key} className="parameter">
            {activityData.message.parameters[key]}
          </span>
        ))
      }
    })

    return content
  }

  isActivityRetryAllowed = (activity) => {
    if (activity.activity_type === this.CAMPAIGN_CREATION_ACTIVITY_TYPE) {
      let activityData = activity.activity_data
      let campaignData = activityData.campaign_data

      // In some cases, campaigns are being partially created due to creative issues for example.
      // In those cases, we would like to prevent the retry operation since it might cause campaign duplications.
      if (
        activityData.message.content.includes("was partially created") &&
        campaignData &&
        !this.PROVIDERS_WITH_PARTIAL_CREATION_RETRY.includes(parseInt(campaignData.provider_id))
      ) {
        return false
      }

      // GoogleAds doesn't have support for retry
      if (parseInt(campaignData.provider_id) === CampaignsConsts.GOOGLE_PROVIDER_ID) {
        return false
      }
    }

    return true
  }

  createActivitiesComponent = (activities, isImportant) => {
    let activitiesArr = []

    activities.forEach((activity) => {
      let campaignData = activity.activity_data.campaign_data
      let activityOptionsMenu = null
      let activityTime = activity.updated_at ? Moment.unix(activity.updated_at) : Moment.unix(activity.created_at)

      let network = null
      let campaignType = null
      let platform = null
      let subPlatform = null
      let countryCode = null
      let providerId = null
      let providerName = null
      let managementUrl = null
      let campaignKey = null

      if (campaignData) {
        network = this.getNetworkTag(campaignData.site_id)
        campaignType = this.getCampaignTypeTag(campaignData.campaign_type)
        platform = PlatformTypes.idToPlatform[campaignData.platform]
        countryCode = campaignData.country_code
          ? getCountryText(campaignData.country_code)
          : Consts.WORLDWIDE_COUNTRY_NAME
        providerId = Number(campaignData.provider_id)
        providerName = this.props.providerIdToProvider.get(providerId)
          ? this.props.providerIdToProvider.get(providerId).name
          : null
        managementUrl = campaignData.management_url
        campaignKey = campaignData.external_id
          ? [campaignData.provider_id, campaignData.external_id, campaignData.site_id, campaignData.platform].join(" ")
          : null
      }

      let requestId = activity.request_id
      let content = this.getActivityContent(activity.activity_data)
      let platformView = platform ? <span>{platform}</span> : null
      let countryCodeView = countryCode ? <span>{countryCode}</span> : null
      let providerNameView = providerName ? <span>{providerName}</span> : null

      if (
        this.ACTIVITY_STATUSES_WITH_OPTIONS_MENU.includes(activity.status) ||
        activity.activity_type === this.CAMPAIGN_CREATION_ACTIVITY_TYPE
      ) {
        if (!this.isActivityRetryAllowed(activity)) {
          requestId = null
        }

        activityOptionsMenu = (
          <ActivityOptionsMenu
            activityId={activity.id}
            providerName={providerName}
            requestId={requestId}
            managementUrl={managementUrl}
            isImportant={isImportant}
            activityStatus={activity.status}
            activityType={activity.activity_type}
            activityData={activity.activity_data}
            campaignKey={campaignKey}
          />
        )
      }
      const friendlyMessage = activity.activity_data.message.friendly_message ? (
        <div className="friendly-message left">
          <span>{activity.activity_data.message.friendly_message.replace("[campaign_name]", "")}</span>
        </div>
      ) : null

      activitiesArr.push(
        <div className={"activity" + (isImportant ? " important" : "")} key={"activity - " + activity.id}>
          <div className="d-flex">
            <div className="content left">{content}</div>
            <div className="right">{activityOptionsMenu}</div>
          </div>
          {friendlyMessage}
          <div className="activity-bottom flex-row">
            <span>{this.activityToIcon[activity.status]}</span>
            <span>{activityTime.fromNow()}</span>
            {network}
            <span>{activity.user_name}</span>
            {platformView}
            {countryCodeView}
            {providerNameView}
            {campaignType}
          </div>
        </div>
      )
    })

    return activitiesArr
  }

  dismissActivities = () => {
    this.props.dispatch(dismissAllActivities())
  }

  retryAllActivities = () => {
    let activityIds = []
    let minderRequestIds = []

    this.props.activities.important.forEach((activity, activityId) => {
      let requestId = activity.request_id

      // Only allow retry for activities of campaign management or campaign creation with request data
      if (
        ((requestId && activity.activity_type !== this.CAMPAIGN_CREATION_ACTIVITY_TYPE) ||
          (activity?.activity_data?.request_data && activity.activity_type === this.CAMPAIGN_CREATION_ACTIVITY_TYPE)) &&
        this.isActivityRetryAllowed(activity)
      ) {
        activityIds.push(activityId)
        if (requestId) {
          minderRequestIds.push(requestId)
        }
      }
    })

    if (activityIds.length > 0) {
      this.props.dispatch(retryMultipleActivities(activityIds, minderRequestIds))
    }
  }

  getMoreActivities = () => {
    getMoreElements(".drawer", () => this.props.dispatch(showMoreActivitiesAsync(this.props.lastActivityTime)))
  }

  render() {
    let noData = null
    let activitiesChip = null
    let subTitle = null
    let body = null
    let divider = null
    let dismissAllBtn = null
    let retryAllBtn = null

    if (this.props.activities.important.size === 0 && this.props.activities.other.size === 0) {
      noData = <NoData text="Your activity will appear here" />
    } else {
      if (
        this.props.activities.other &&
        this.props.activities.other.size > 0 &&
        this.props.activities.important &&
        this.props.activities.important.size
      ) {
        divider = <Divider classes={{ root: "drawer-divider" }} />
      }
      if (this.props.updates > 0) {
        let activitiesAmount =
          this.props.updates >= Consts.DRAWER_PAGE_SIZE ? Consts.DRAWER_PAGE_SIZE + "+" : this.props.updates

        activitiesChip = <Chip classes={{ root: "activities-chip" }} label={activitiesAmount + " actions required"} />
        subTitle = <div className="sub-title">Actions required</div>
        dismissAllBtn = (
          <div className="dismiss-all-btn clickable" onClick={this.dismissActivities}>
            Dismiss all
          </div>
        )
        retryAllBtn = (
          <div className="retry-all-btn clickable" onClick={this.retryAllActivities}>
            Retry all
          </div>
        )
      }
    }

    if (!this.props.activities.important) {
      this.importantActivities = []
    }
    if (!this.props.activities.other) {
      this.otherActivities = []
    }

    if (this.state.isLoading) {
      body = (
        <div className="drawer-body drawer-loading d-flex align-items-center">
          <CircularProgress className="loader" size={40} />
        </div>
      )
    } else {
      body = (
        <div className="drawer-body">
          {activitiesChip}
          <div className="d-flex flex-row justify-content-between">
            {subTitle}
            <div className="activity-buttons">
              {retryAllBtn}
              {dismissAllBtn}
            </div>
          </div>
          {noData}
          <div>{this.importantActivities}</div>
          {divider}
          <div>{this.otherActivities}</div>
        </div>
      )
    }

    return (
      <div className="d-flex flex-column align-items-center align-content-center">
        <div className="title">Activities</div>
        {body}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    siteIdToNetwork: state.app.siteIdToNetwork,
    activities: state.navigationBar.allActivities,
    providerIdToProvider: state.app.providerIdToProvider,
    updates: state.navigationBar.drawer.updates.activities,
    lastActivityTime: state.navigationBar.drawer.lastObjTime.activities,
  }
}

export default connect(mapStateToProps)(ActivitiesDrawer)
