import draftsActionTypes from "./draftsActionTypes"
import { getEndDate, getStartDate, TODAY } from "../common/components/datePickerV2"
import Moment from "moment"
import WebStorageConsts from "../common/consts/webStorageConsts"
import { webStorage } from "../api/webStorage"
import { draftCreationStatus } from "./components/draftCreationStatus"

function getDefaultItemsPerPage() {
  let itemsPerPage = 50

  if (webStorage.get(WebStorageConsts.STORAGE_KEYS.CAMPAIGNS_ITEMS_PER_PAGE)) {
    itemsPerPage = parseInt(webStorage.get(WebStorageConsts.STORAGE_KEYS.CAMPAIGNS_ITEMS_PER_PAGE))
  }

  return itemsPerPage
}

const draftsState = {
  datePickerDates: {
    startDate: getStartDate(TODAY),
    endDate: getEndDate(TODAY, true),
    showLastHour: true,
    isGeneratedReport: false,
    dateType: TODAY,
  },
  isToday: false,
  allDrafts: [], // Visible campaigns according to sorting, searching & filtering
  totalDrafts: 0, // Total number of drafts
  itemsPerPage: getDefaultItemsPerPage(),
  currentPage: 1,
  lastUpdate: null,
  shouldUpdatePage: false,
  filters: new Map(),
  draftsForRetry: [],
}

let addCampaignsProps = (campaigns) => {
  return campaigns
    ? Object.keys(campaigns).map((key) => {
        var campaign = Object.assign({}, campaigns[key])
        campaign.key = key
        campaign.tracking_code = campaign.campaign_obj?.tracking_code || campaign.campaign_obj?.utm_campaign
        campaign.countries = campaign.campaign_obj?.country_code || campaign.campaign_obj?.countries
        campaign.country_group = campaign.campaign_obj?.country_group
        campaign.country_group_id = campaign.campaign_obj?.country_group_id
        campaign.language_code = campaign.campaign_obj?.language_code
        campaign.provider_id = campaign.campaign_obj?.provider_id
        campaign.operating_systems = []
        campaign.device_platforms = []
        if (campaign.campaign_obj?.platforms) {
          campaign.campaign_obj.platforms.map((platform) => {
            campaign.device_platforms.push(platform.name.toUpperCase())
            platform.operating_systems.map((os) => {
              campaign.operating_systems.push(os.toUpperCase())
            })
          })
        }
        campaign.device_targeting_group_id = campaign.campaign_obj?.device_targeting_group_id
        campaign.tags =
          campaign.campaign_obj?.additional_creation_parameters?.selected_tags || campaign.campaign_obj?.tags
        return campaign
      })
    : null
}

let getAllFailedDrafts = (campaigns) => {
  return campaigns
    ? Object.keys(campaigns)
        .filter((key) => campaigns[key].creation_status === draftCreationStatus.failed.value)
        .map((key) => {
          var campaign = Object.assign({}, campaigns[key])
          campaign.key = key
          return campaign
        })
    : null
}

let changeDraftStatus = (allDrafts, draftId) => {
  return Object.keys(allDrafts).map((key) => {
    var draft = Object.assign({}, allDrafts[key])
    if (draft.id === draftId) {
      draft.creation_status = draftCreationStatus.in_progress.value
    }
    return draft
  })
}

function draftsReducer(state = draftsState, action) {
  if (state == undefined || action == undefined) {
    return []
  }
  switch (action.type) {
    case draftsActionTypes.RESET_DRAFTS_DATES:
      return Object.assign({}, state, {
        datePickerDates: draftsState.datePickerDates,
      })

    case draftsActionTypes.DRAFTS_DATEPICKER_DATES_SELECTED:
      let today = Moment()
      let isToday = false

      if (today.isSame(action.startDate, "day")) {
        isToday = true
      }

      return Object.assign({}, state, {
        datePickerDates: {
          startDate: action.startDate,
          endDate: action.endDate,
          isGeneratedReport: false,
        },
        isToday,
      })

    case draftsActionTypes.FETCH_DRAFTS_RESPONSE:
      return Object.assign({}, state, {
        allDrafts: addCampaignsProps(action.campaigns),
        hasCampaigns: action.hasCampaigns,
        totalDrafts: action.totalDrafts,
        lastUpdate: Moment().unix(),
        isLoading: false,
        draftsForRetry: getAllFailedDrafts(action.campaigns),
      })

    case draftsActionTypes.DRAFTS_LIST_LOADING:
      return Object.assign({}, state, {
        isLoading: action.isLoading,
      })

    case draftsActionTypes.UPDATE_FILTER_DRAFTS:
      return Object.assign({}, state, {
        filters: action.filters,
      })

    case draftsActionTypes.ROLLBACK_DRAFTS_PAGE:
      return Object.assign({}, state, {
        isLoading: false,
        hasCampaigns: true,
        allDrafts: [],
        totalDrafts: 0,
        filters: new Map(),
        draftsForRetry: [],
      })

    case draftsActionTypes.UPDATE_DRAFTS_PAGE:
      return Object.assign({}, state, {
        currentPage: action.currentPage,
        shouldUpdatePage: true,
      })

    case draftsActionTypes.RESET_DRAFTS_PAGINATION:
      return Object.assign({}, state, {
        currentPage: 1,
        shouldUpdatePage: true,
      })

    case draftsActionTypes.UPDATE_ITEMS_PER_PAGE:
      return Object.assign({}, state, {
        itemsPerPage: action.itemsPerPage,
      })
    case draftsActionTypes.SHOULD_UPDATE_DRAFTS_PAGE:
      return Object.assign({}, state, {
        shouldUpdatePage: action.shouldUpdate,
      })
    case draftsActionTypes.UPDATE_DRAFT_STATUS_TO_IN_PROGRESS:
      return Object.assign({}, state, {
        allDrafts: changeDraftStatus(state.allDrafts, action.draftId),
      })
    case draftsActionTypes.RESET_DRAFTS_FOR_RETRY:
      return Object.assign({}, state, {
        draftsForRetry: [],
      })

    default:
      return state
  }
}
export { draftsReducer }
