import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Typography, TextField } from "@material-ui/core"
import { KeyboardArrowRight, KeyboardArrowDown } from "@material-ui/icons"
import AuthApi from "../../../../api/authService"
import ModuleTypes from "../../../../common/consts/moduleTypes"

const DuplicateCountToggle = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [numDuplicates, setNumDuplicates] = useState(props.numDuplicatesPerTarget)
  const MAX = 20

  useEffect(() => {
    if (props.numDuplicatesPerTarget) {
      setNumDuplicates(props.numDuplicatesPerTarget)
    }
  }, [props.numDuplicatesPerTarget])

  const toggle = () => {
    setIsOpen((prev) => !prev)
  }

  const handleChange = (event) => {
    const input = event.target.value
    if (input === "") {
      setNumDuplicates(input)
      return
    }

    const value = parseInt(input, 10)
    if (!isNaN(value) && value.toString() === input.toString() && value > 0 && value <= MAX) {
      setNumDuplicates(value)
      if (props.onChange) {
        props.onChange({ numDuplicatesPerTarget: value })
      }
    }
  }

  const handleKeyDown = (event) => {
    if (!event.key) return
    switch (event.key) {
      case "ArrowUp":
        handleStepper("plus")
        break
      case "ArrowDown":
        handleStepper("minus")
        break
      default:
        break
    }
  }

  const handleStepper = (dir) => {
    let value = parseInt(numDuplicates || 0, 10)
    if (dir === "plus" && value < MAX) value++
    if (dir === "minus" && value > 1) value--
    setNumDuplicates(value)
    if (props.onChange) {
      props.onChange({ numDuplicatesPerTarget: value })
    }
  }

  if (!AuthApi.hasModule(ModuleTypes.BULK_MULTIPLE_CAMPAIGNS)) {
    return null
  }

  return (
    <div className="bulk-duplicate-multiple-campaigns-toggle">
      <div className="multiple-campaigns-toggle">
        <Typography
          variant="subtitle1"
          onClick={toggle}
          style={{ cursor: "pointer", fontWeight: "bold", fontSize: "12px" }}
        >
          {isOpen ? <KeyboardArrowDown viewBox="0 -6 24 24" /> : <KeyboardArrowRight viewBox="0 -6 24 24" />}
          Number of Duplicates
        </Typography>
      </div>

      {isOpen && (
        <div className="duplicate-multiple-campaigns">
          <span>Create multiple copies of each campaign variation instantly</span>
          <div className="duplicate-multiple-campaigns-selection">
            <div className="dynamic-button dynamic-plus-button" onClick={() => handleStepper("plus")}>
              +
            </div>
            <div className="dynamic-button dynamic-minus-button" onClick={() => handleStepper("minus")}>
              −
            </div>
            <TextField
              type="number"
              inputProps={{ min: 1 }}
              InputProps={{ className: "text-field-line" }}
              InputLabelProps={{ classes: { shrink: "text-field-color" } }}
              className="dynamic-text-field"
              value={numDuplicates}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              disabled={numDuplicates >= MAX}
            />
          </div>
        </div>
      )}
    </div>
  )
}

function mapStateToProps(state) {
  return {}
}

export default connect(mapStateToProps)(DuplicateCountToggle)
