import merge from "deepmerge"
import { store } from "../app/store"
import CampaignsCreationConsts from "./campaignCreationConsts"
import { isNullOrEmpty, isNullOrUndefined } from "../utils/funcUtils"
import { buildCampaignName, getPlatformText } from "../utils/campaignUtilsV2"
import CampaignsCreationSourcesInitialState from "./campaignCreationSourcesInitialState"
import CampaignCreationActionTypes from "./campaignCreationActionTypes"
import ActionTypes from "../common/actions/actionTypes"
import AdTypes from "../common/consts/adTypes"
import ThumbnailTypes from "../common/consts/thumbnailTypes"
import { alpha2ToAlpha3 } from "i18n-iso-countries"
import Consts from "../app/consts"
import CampaignsConsts from "../campaignsV2/campaignsConsts"

export const campaignTargetingGroup = {
  isOpen: true,
  providerAccount: null,
  selectedCountries: [],
  selectedCountryGroups: [],
  selectedConversionEvents: [],
  selectedTags: [],
  nameSuffix: "",
  initialBid: null,
  initialBudget: null,
  changedFieldsFromParentCampaign: [],
  isActive: false,
  selectedDeviceTargetingGroup: null,
  isDeviceTargetingEnabled: false,
}

export const campaignCreationWizardInitialState = {
  isOpen: false,
  isLoading: false,
  isLaunching: false,
  currentStep: CampaignsCreationConsts.CREATION_WIZARD_STEPS.sources,
  isDuplication: false,
  isDuplicationFromBulk: false,
  parentCampaign: {
    key: null,
    providerId: null,
  },
  duplicationInitialState: {},
  originalCampaignTrackingCode: null,
  duplicationTimestamp: null,
  changedFieldsFromParentCampaign: [],
  wasDuplicateReviewWarningShown: false,
  providerAccounts: [],
  networkTags: [],
  isLoadingProviderAccounts: false,
  isLoadingCreationSettings: false,
  campaignSettings: {
    siteId: "",
    campaignId: "",
    siteDomain: "",
    possibleSitesForDomain: [],
    networkId: "",
    networkCode: "",
    name: "",
    articleUrl: "",
    selectedKeywords: [],
    articleKeywords: [],
    isRsoc: false,
    articleIsArchived: true,
    layoutArticleUrl: null,
    layout: "",
    queryParam: null,
    selectedCountries: [],
    selectedLanguages: [],
    selectedCountryGroups: [],
    selectedTags: [],
    recentCountries: [],
    recentLanguages: [],
    selectedDeviceTargetingGroup: null,
    isDeviceTargetingEnabled: false,
  },
  campaignTargetingGroups: [],
  campaignSources: {
    sources: Object.assign({}, CampaignsCreationSourcesInitialState.CAMPAIGN_CREATION_SOURCES),
  },
  campaignCreatives: {
    isFetchingCreatives: false,
    creativeIdCounter: 0,
    creatives: [],
    autoGenerateText: null,
    hasAutoGeneratePermission: false,
    dynamicCreative: {
      descriptions: ["", "", "", "", ""],
      headlines: ["", "", "", "", ""],
      primaryTexts: ["", "", "", "", ""],
      longHeadline: [""],
      thumbnails: [],
      displayThumbnails: [],
    },
    creativesGroups: [],
    isDynamicCreative: null,
  },
  isActive: false, //for bulk only
}
// Setting overwriteMerge when using merge function in order to overwrite the existing array values
export const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray

// TODO: think about the weird function name
function isUserInTheMidstOfTheWizard(state) {
  let currentState = Object.assign({}, state)
  let initialState = Object.assign({}, campaignCreationWizardInitialState)
  delete currentState.isOpen
  delete initialState.isOpen

  // Comparing the current state to the initial state, if they're different we show the user a dialog to prevent him
  // from accidentally exiting the wizard.
  return JSON.stringify(currentState) !== JSON.stringify(initialState)
}

export function generateCreativeObject(fields, ad_type_id = AdTypes.regular_ad.id) {
  const defaultValues = ["", "", "", "", ""]
  let creativeObject =
    ad_type_id === AdTypes.dynamic_ad.id
      ? {
          id: fields?.id,
          type: AdTypes.dynamic_ad.id,
          headlines: fields.headlines
            ? fields.headlines.concat(defaultValues.slice(fields.headlines.length))
            : defaultValues,
          descriptions: fields.descriptions
            ? fields.descriptions.concat(defaultValues.slice(fields.descriptions.length))
            : defaultValues,
          primaryTexts: fields.primary_texts
            ? fields.primary_texts.concat(defaultValues.slice(fields.primary_texts.length))
            : defaultValues,
          thumbnails: fields.thumbnails
            ? convertToThumbnails(fields.thumbnails).concat(createThumbnails(10, Object.keys(fields.thumbnails).length))
            : createThumbnails(10),
          callToAction: fields.call_to_action || "",
        }
      : {
          id: null,
          text: "",
          description: "",
          headline: "",
          thumbnail: null,
          is_video: false,
          thumbnailErrors: [],
          croppedImagesPerSource: {},
          ...fields,
        }
  return {
    ...creativeObject,
  }
}

export function convertRegularCreativesToDynamic(regularCreatives) {
  let descriptions = []
  let headlines = []
  let primaryTexts = []

  regularCreatives.forEach((creative) => {
    if (!isNullOrEmpty(creative.description)) {
      descriptions.push(creative.description)
    }
    if (!isNullOrEmpty(creative.headline)) {
      headlines.push(creative.headline)
    }
    if (!isNullOrEmpty(creative.text)) {
      primaryTexts.push(creative.text)
    }
  })

  let dynamicCreative = [
    generateCreativeObject(
      {
        id: 0,
        descriptions,
        headlines,
        primary_texts: primaryTexts,
        thumbnails: convertToThumbnails(regularCreatives),
      },
      AdTypes.dynamic_ad.id
    ),
  ]
  return dynamicCreative
}

export function convertDynamicToRegular(dynamicCreative) {
  let regularCreatives = []
  let regularCreative = {}
  if (Array.isArray(dynamicCreative[0].thumbnails)) {
    dynamicCreative[0].thumbnails.forEach((thumbnail, index) => {
      if (!isNullOrEmpty(thumbnail.thumbnail)) {
        regularCreative = generateCreativeObject(
          {
            id: index,
            text: Array.isArray(dynamicCreative[0].primaryTexts) ? dynamicCreative[0].primaryTexts[index] : "",
            description: Array.isArray(dynamicCreative[0].descriptions) ? dynamicCreative[0].descriptions[index] : "",
            headline: Array.isArray(dynamicCreative[0].headlines) ? dynamicCreative[0].headlines[index] : "",
            thumbnail: thumbnail.thumbnail,
          },
          AdTypes.regular_ad.id
        )
        regularCreatives.push(regularCreative)
      }
    })
  }

  return regularCreatives
}

export function generateGoogleCreativeObject(fields, ad_type) {
  let numOfThumbnails = 20
  const defaultValues = ["", "", "", "", ""]
  let displayThumbnails = []
  if (ad_type === AdTypes.display_ad.id) {
    numOfThumbnails = 15
    displayThumbnails = fields.displayThumbnails
      ? fields.displayThumbnails.concat(createThumbnails(10 - Object.keys(fields["displayThumbnails"]).length))
      : createThumbnails(10)
  }

  fields = Array.isArray(fields) ? fields[0] : fields
  let creativeGoogleObject = {
    type: fields.type || "",
    headlines: fields.headlines ? fields.headlines.concat(defaultValues.slice(fields.headlines.length)) : defaultValues,
    descriptions: fields.descriptions
      ? fields.descriptions.concat(defaultValues.slice(fields.descriptions.length))
      : defaultValues,
    thumbnails: fields.thumbnails
      ? fields.thumbnails.concat(createThumbnails(numOfThumbnails, Object.keys(fields.thumbnails).length))
      : createThumbnails(numOfThumbnails),
    callToAction: fields.call_to_action || "",
    longHeadline: fields.long_headline ? fields.long_headline : [""],
    displayThumbnails: fields.display_thumbnails
      ? fields.display_thumbnails.concat(createThumbnails(10, Object.keys(fields.display_thumbnails).length))
      : createThumbnails(10),
  }

  return {
    ...creativeGoogleObject,
  }
}
export function generateCropDetails(adTypes, providerKey) {
  let cropDetails = CampaignsCreationConsts.CROP_RATIOS[providerKey]
  const selectedAdType = adTypes.find((adType) => adType.selected)
  if (!isNullOrEmpty(selectedAdType) && selectedAdType.id === AdTypes.display_ad.id)
    return Object.fromEntries(Object.entries(cropDetails).filter(([key, value]) => !value.isDiscoveryOnly))

  return cropDetails
}

function createThumbnails(numOfThumbnails = 20, startId = 0) {
  const thumbnails = []
  for (let i = startId; i < numOfThumbnails; i++) {
    thumbnails.push({
      id: i,
      thumbnail: null,
      croppedImagePerRatios: {},
      thumbnailErrors: [],
      isThumbnailBeingUploaded: false,
      is_video: false,
    })
  }
  return thumbnails
}

function convertToThumbnails(creatives) {
  const thumbnails = []
  Object.values(creatives).forEach((creative) => {
    thumbnails.push({
      id: creative.id,
      thumbnail: creative?.thumbnail,
      croppedImagePerRatios: {},
      thumbnailErrors: [],
      isThumbnailBeingUploaded: false,
      is_video: creative?.is_video,
    })
  })
  return thumbnails
}

function onBeforeUnloadHandler(e) {
  let state = store.getState().campaignCreationWizard
  if (isUserInTheMidstOfTheWizard(state)) {
    e.returnValue = "Are you sure you want to leave this page?"
  }
}

function updateCreative(creatives, action, fieldsToUpdate, mergeFields = false) {
  for (let i = 0; i < creatives.length; i++) {
    let creative = creatives[i]

    if (creative.id === action.id) {
      if (mergeFields) {
        Object.assign(creative, merge(creative, fieldsToUpdate))
      } else {
        // Fields will be overriden
        Object.assign(creative, fieldsToUpdate)
      }

      break
    }
  }
}

function removeCreative(creatives, action) {
  for (let i = 0; i < creatives.length; i++) {
    let creative = creatives[i]

    if (creative.id === action.id) {
      // Remove the creative
      creatives.splice(i, 1)

      break
    }
  }
}

function cloneCreative(creatives, action, newCreativeId) {
  for (let i = 0; i < creatives.length; i++) {
    let creative = creatives[i]

    if (creative.id === action.id) {
      let clonedCreative = merge({}, creative)
      clonedCreative.id = newCreativeId

      // Pushing the cloned creative to be the next creative of the current one.
      // Example:
      // [creativeA, creativeB, creativeC]
      // After cloning A:
      // [creativeA, cloneCreativeA, creativeB, creativeC]
      creatives.splice(i + 1, 0, clonedCreative)

      break
    }
  }
}

function getCreativesFromState(state, groupIndex) {
  return isNullOrUndefined(groupIndex)
    ? merge({}, state.campaignCreatives.creatives)
    : merge({}, state.campaignCreatives.creativesGroups[groupIndex].creatives)
}

function updateCreativesState(state, creatives, groupIndex, creativeIdCounter = null) {
  if (isNullOrUndefined(groupIndex)) {
    return Object.assign({}, state, {
      campaignCreatives: Object.assign({}, state.campaignCreatives, {
        creativeIdCounter: isNullOrUndefined(creativeIdCounter)
          ? state.campaignCreatives.creativeIdCounter
          : creativeIdCounter,
        creatives,
      }),
    })
  } else {
    let creativesGroups = merge({}, state.campaignCreatives.creativesGroups)
    creativesGroups[groupIndex].creatives = creatives
    return Object.assign({}, state, {
      campaignCreatives: Object.assign({}, state.campaignCreatives, {
        creativeIdCounter: isNullOrUndefined(creativeIdCounter)
          ? state.campaignCreatives.creativeIdCounter
          : creativeIdCounter,
        creativesGroups,
      }),
    })
  }
}

export function updateDynamicThumbnail(dynamicCreative, action, fieldsToUpdate, mergeFields = false) {
  let thumbnailAttributes = null
  if (action.thumbnailTypeId === ThumbnailTypes.RESPONSIVE_THUMBNAIL.id) {
    thumbnailAttributes = dynamicCreative.thumbnails[action.id]
  } else if (action.thumbnailTypeId === ThumbnailTypes.DISPLAY_THUMBNAIL.id) {
    thumbnailAttributes = dynamicCreative.displayThumbnails[action.id]
  }
  if (mergeFields) {
    Object.assign(thumbnailAttributes, merge(thumbnailAttributes, fieldsToUpdate))
  } else {
    // Fields will be overriden
    Object.assign(thumbnailAttributes, fieldsToUpdate)
  }
}

function campaignCreationReducer(state = campaignCreationWizardInitialState, action) {
  if (state == undefined || action == undefined) {
    return []
  }

  switch (action.type) {
    case CampaignCreationActionTypes.OPEN_CAMPAIGN_CREATION_WIZARD:
      window.addEventListener("beforeunload", onBeforeUnloadHandler)

      if (action.duplication && action.campaign) {
        // If we're in duplication, we set most of the available data into the relevant creation steps
        let url = action.campaign.trimmedUrl ? action.campaign.trimmedUrl : action.campaign.url
        const currentQueryParams = new URL(action.campaign.link_url).searchParams
        let selectedKeywords = currentQueryParams.get("keywords") ? currentQueryParams.get("keywords").split(",") : []
        let languageCode = action.campaign.language
          ? action.campaign.language
          : action.campaign.targeting?.locales
          ? action.campaign.targeting.locales[0]
          : null
        if (!url) {
          url = action.campaign.article_link
        }

        let campaignSources = merge({}, state.campaignSources)
        let providerKey

        Object.keys(campaignSources.sources).forEach((sourceKey) => {
          let source = campaignSources.sources[sourceKey]
          if (source.id === action.campaign.provider_id) {
            source.selected = true
            providerKey = sourceKey
            if (!isNullOrEmpty(action.campaign.ad_type) & (source.adTypes.length > 1)) {
              source.adTypes.filter((adType) => adType.id === action.campaign.ad_type)[0].selected = true
            }
            if (source.id === CampaignsConsts.GOOGLE_PROVIDER_ID) {
              source.cropDetails = generateCropDetails(source.adTypes, providerKey)
            }
          }
        })

        let targetingGroup = merge({}, campaignTargetingGroup)
        targetingGroup = { ...targetingGroup, ...merge({}, state.campaignSources.sources[providerKey]) }

        if (!isNullOrEmpty(action.campaign.bid_mode)) {
          targetingGroup.campaignTypes = targetingGroup.campaignTypes.map((campaignType) =>
            campaignType.id === action.campaign.bid_mode ? { ...campaignType, selected: true } : campaignType
          )
        }

        let platformKey = getPlatformText(action.campaign.device_platforms)

        if (targetingGroup.platforms[platformKey]) {
          targetingGroup.platforms[platformKey].selected = true

          // Setting every "value" under values to be selected for this platform (for example: setting both android and ios selected if we're on mobile)
          targetingGroup.platforms[platformKey].values = Object.keys(
            targetingGroup.platforms[platformKey].values
          ).reduce((platformValues, platformValueKey) => {
            platformValues[platformValueKey] = true

            return platformValues
          }, {})
        }
        let countries = action.campaign.targeting?.geo_locations?.countries
        if (!isNullOrEmpty(action.campaign.country_group_id)) {
          targetingGroup.selectedCountryGroups = [
            {
              countries:
                (!isNullOrEmpty(countries) && countries.length) > 1
                  ? countries.map((countryCode) => alpha2ToAlpha3(countryCode))
                  : [],
              name: action.campaign.country_group_name,
              selected: true,
              id: action.campaign.country_group_id,
            },
          ]
        } else {
          if (!isNullOrEmpty(countries)) {
            targetingGroup.selectedCountries = countries.map((countryCode) => alpha2ToAlpha3(countryCode))
          }
        }

        if (!isNullOrEmpty(action.campaign.device_targeting_group_id)) {
          targetingGroup.selectedDeviceTargetingGroup = {
            value: action.campaign.device_targeting_group_id,
            name: action.campaign.device_targeting_group_name,
          }
          targetingGroup.isDeviceTargetingEnabled = true
        }

        targetingGroup.selectedTags = action.campaign.tags

        if (!isNullOrUndefined(action.campaign.conversion_value) && action.campaign.conversion_value !== "--") {
          targetingGroup.selectedConversionEvents = [
            { name: action.campaign.conversion_name, value: action.campaign.conversion_value },
          ]
        }

        let campaignTargetingGroups = [targetingGroup]

        let newState = Object.assign({}, state, {
          isOpen: true,
          providerAccounts: action.providerAccounts ? action.providerAccounts : [],
          isLoading: action.isLoading ? action.isLoading : false,
          parentCampaign: {
            key: action.campaign.key,
            providerId: action.campaign.provider_id,
            trackingCode: action.campaign.tracking_code,
          },
          campaignSettings: Object.assign({}, state.campaignSettings, {
            siteId: action.campaign.site_id,
            // cutting the last part of the tracking code, it's the "- DESKTOP USA" part which isn't needed
            name: buildCampaignName(action.campaign.tracking_code, action.campaign.provider_id),
            articleUrl: url,
            selectedKeywords: selectedKeywords,
            selectedLanguages: isNullOrEmpty(languageCode) ? [] : [languageCode.toUpperCase()],
            campaignId: action.campaign.campaign_id,
          }),
          campaignSources,
          campaignTargetingGroups,
          isDuplication: true,
        })

        return newState
      }

      return Object.assign({}, state, {
        isOpen: true,
        isLoading: action.isLoading ? action.isLoading : false,
        campaignSettings: Object.assign({}, state.campaignSettings, {
          selectedUUid: window.crypto.randomUUID().split("-")[3],
        }),
      })

    case CampaignCreationActionTypes.CLOSE_CAMPAIGN_CREATION_WIZARD:
      if (
        action.showWarningDialog &&
        isUserInTheMidstOfTheWizard(state) &&
        !window.confirm("Are you sure you want to exit the wizard?")
      ) {
        return state
      }

      window.removeEventListener("beforeunload", onBeforeUnloadHandler)
      return campaignCreationWizardInitialState

    case CampaignCreationActionTypes.CHANGE_WIZARD_STEP:
      return Object.assign({}, state, {
        currentStep: action.stepNum,
      })

    case CampaignCreationActionTypes.UPDATE_STATUS:
      return Object.assign({}, state, {
        isActive: action.isActive,
      })

    case CampaignCreationActionTypes.ADD_CREATIVE:
      var creativeId = state.campaignCreatives.creativeIdCounter + 1
      var creatives = getCreativesFromState(state, action.groupIndex)
      var selectedSourceKey = Object.keys(state.campaignSources.sources).filter(
        (sourceKey) => state.campaignSources.sources[sourceKey].selected
      )[0]
      var selectedAdType = state.campaignSources.sources[selectedSourceKey].adTypes.filter(
        (adType) => adType.selected
      )[0]
      creatives.push(generateCreativeObject({ id: creativeId }, selectedAdType.id))

      if (isNullOrUndefined(action.groupIndex)) {
        return Object.assign({}, state, {
          campaignCreatives: Object.assign({}, state.campaignCreatives, {
            creativeIdCounter: creativeId,
            creatives,
          }),
        })
      }

      var creativesGroups = merge({}, state.campaignCreatives.creativesGroups)
      creativesGroups[action.groupIndex].creatives = creatives
      return Object.assign({}, state, {
        campaignCreatives: Object.assign({}, state.campaignCreatives, {
          creativeIdCounter: creativeId,
          creativesGroups,
          isDynamicCreative: selectedAdType.id === AdTypes.dynamic_ad.id,
        }),
      })

    case CampaignCreationActionTypes.ADD_CREATIVES_GROUP:
      var creativesGroups = merge({}, state.campaignCreatives.creativesGroups)
      var selectedSourceKey = Object.keys(state.campaignSources.sources).filter(
        (sourceKey) => state.campaignSources.sources[sourceKey].selected
      )[0]
      var selectedAdType = state.campaignSources.sources[selectedSourceKey].adTypes.filter(
        (adType) => adType.selected
      )[0]
      let creativesGroupToAdd = {
        isOpen: true,
        creatives: [generateCreativeObject({ id: 0 }, selectedAdType.id)],
      }

      creativesGroups.push(creativesGroupToAdd)

      return Object.assign({}, state, {
        campaignCreatives: Object.assign({}, state.campaignCreatives, {
          isDynamicCreative: selectedAdType.id === AdTypes.dynamic_ad.id,
          creativesGroups,
        }),
      })

    case CampaignCreationActionTypes.TOGGLE_CREATIVES_GROUP_OPEN:
      var creativesGroups = merge({}, state.campaignCreatives.creativesGroups)

      creativesGroups.forEach((creativeGroup, groupIndex) => {
        if (groupIndex === action.groupIndex) {
          creativeGroup.isOpen = !creativeGroup.isOpen
        } else {
          creativeGroup.isOpen = false
        }
      })

      return Object.assign({}, state, {
        campaignCreatives: Object.assign({}, state.campaignCreatives, {
          creativesGroups,
        }),
      })

    case CampaignCreationActionTypes.REMOVE_CREATIVES_GROUP: {
      var creativesGroups = merge({}, state.campaignCreatives.creativesGroups)
      creativesGroups.splice(action.groupIndex, 1)

      return Object.assign({}, state, {
        campaignCreatives: Object.assign({}, state.campaignCreatives, {
          creativesGroups,
        }),
      })
    }

    case CampaignCreationActionTypes.DUPLICATE_CREATIVES_GROUP: {
      var campaignCreativesGroups = merge({}, state.campaignCreatives.creativesGroups)
      let creativesGroupToAdd = merge({}, action.creativesGroupData)

      campaignCreativesGroups.push(creativesGroupToAdd)

      return Object.assign({}, state, {
        campaignCreatives: Object.assign({}, state.campaignCreatives, {
          creativesGroups: campaignCreativesGroups,
        }),
      })
    }

    case CampaignCreationActionTypes.UPDATE_THUMBNAIL_RESPONSE:
      var creatives = getCreativesFromState(state, action.groupIndex)
      updateCreative(creatives, action, {
        thumbnail: action.url,
        croppedImagesPerSource: {},
        is_video: action.isVideo,
      })
      return updateCreativesState(state, creatives, action.groupIndex)

    case CampaignCreationActionTypes.UPDATE_DYNAMIC_THUMBNAIL_RESPONSE:
      var dynamicCreative = merge({}, state.campaignCreatives.dynamicCreative)

      if (isNullOrUndefined(action.groupIndex)) {
        updateDynamicThumbnail(dynamicCreative, action, {
          thumbnail: action.url,
          croppedImagePerRatios: {},
          isThumbnailBeingUploaded: false,
          is_video: action.isVideo,
        })

        return Object.assign({}, state, {
          campaignCreatives: Object.assign({}, state.campaignCreatives, {
            dynamicCreative,
          }),
        })
      }

      dynamicCreative = merge({}, state.campaignCreatives.creativesGroups[action.groupIndex].creatives[0])
      updateDynamicThumbnail(dynamicCreative, action, {
        thumbnail: action.url,
        croppedImagePerRatios: {},
        is_video: action.isVideo,
      })

      var creativesGroups = merge({}, state.campaignCreatives.creativesGroups)
      creativesGroups[action.groupIndex].creatives[0] = dynamicCreative
      return Object.assign({}, state, {
        campaignCreatives: Object.assign({}, state.campaignCreatives, {
          isDynamicCreative: true,
          creativesGroups,
        }),
      })

    case CampaignCreationActionTypes.UPDATE_THUMBNAIL_PER_SOURCE_RESPONSE:
      var creatives = getCreativesFromState(state, action.groupIndex)
      updateCreative(
        creatives,
        action,
        {
          croppedImagesPerSource: {
            [action.sourceKey]: {
              thumbnail: action.url,
              cropDetails: action.cropDetails,
              selectedRatio: action.selectedRatio,
            },
          },
        },
        true
      )
      return updateCreativesState(state, creatives, action.groupIndex)

    case CampaignCreationActionTypes.UPDATE_THUMBNAIL_PER_RATIO_RESPONSE:
      var dynamicCreative = merge({}, state.campaignCreatives.dynamicCreative)
      var creativesGroups = merge({}, state.campaignCreatives.creativesGroups)
      if (action.groupIndex != null) {
        dynamicCreative = merge({}, creativesGroups[action.groupIndex].creatives[0])
      }
      var thumbnailObj = {
        croppedImagePerRatios: {
          [action.selectedRatio]: {
            thumbnail: action.url,
            cropDetails: action.cropDetails,
          },
        },
      }
      if (action.shouldChangeMainThumbnail) {
        thumbnailObj.thumbnail = action.originalThumbnail
        thumbnailObj.isThumbnailBeingUploaded = false
      }
      updateDynamicThumbnail(dynamicCreative, action, thumbnailObj, false) // support single crop

      if (isNullOrUndefined(action.groupIndex)) {
        return Object.assign({}, state, {
          campaignCreatives: Object.assign({}, state.campaignCreatives, {
            dynamicCreative,
          }),
        })
      }

      creativesGroups[action.groupIndex].creatives[0] = dynamicCreative
      return Object.assign({}, state, {
        campaignCreatives: Object.assign({}, state.campaignCreatives, {
          creativesGroups,
        }),
      })

    case CampaignCreationActionTypes.UPDATE_THUMBNAIL_IS_LOADING:
      var creatives = getCreativesFromState(state, action.groupIndex)
      updateCreative(creatives, action, {
        isThumbnailBeingUploaded: action.isLoading,
      })
      return updateCreativesState(state, creatives, action.groupIndex)

    case CampaignCreationActionTypes.UPDATE_DYNAMIC_THUMBNAIL_IS_LOADING:
      var dynamicCreative = merge({}, state.campaignCreatives.dynamicCreative)
      if (isNullOrUndefined(action.groupIndex)) {
        updateDynamicThumbnail(dynamicCreative, action, {
          isThumbnailBeingUploaded: action.isLoading,
        })

        return Object.assign({}, state, {
          campaignCreatives: Object.assign({}, state.campaignCreatives, {
            dynamicCreative,
          }),
        })
      }
      dynamicCreative = merge({}, state.campaignCreatives.creativesGroups[action.groupIndex].creatives[0])
      updateDynamicThumbnail(dynamicCreative, action, {
        isThumbnailBeingUploaded: action.isLoading,
      })

      var creativesGroups = merge({}, state.campaignCreatives.creativesGroups)
      creativesGroups[action.groupIndex].creatives[0] = dynamicCreative
      return Object.assign({}, state, {
        campaignCreatives: Object.assign({}, state.campaignCreatives, {
          creativesGroups,
        }),
      })

    case CampaignCreationActionTypes.VALIDATE_THUMBNAIL:
      var creatives = getCreativesFromState(state, action.groupIndex)
      updateCreative(creatives, action, {
        thumbnailErrors: action.thumbnailErrors,
      })
      return updateCreativesState(state, creatives, action.groupIndex)

    case CampaignCreationActionTypes.VALIDATE_DYNAMIC_THUMBNAIL:
      var dynamicCreative = merge({}, state.campaignCreatives.dynamicCreative)
      if (isNullOrUndefined(action.groupIndex)) {
        updateDynamicThumbnail(dynamicCreative, action, {
          thumbnailErrors: action.thumbnailErrors,
        })

        return Object.assign({}, state, {
          campaignCreatives: Object.assign({}, state.campaignCreatives, {
            dynamicCreative,
          }),
        })
      }
      dynamicCreative = merge({}, state.campaignCreatives.creativesGroups[action.groupIndex].creatives[0])
      updateDynamicThumbnail(dynamicCreative, action, {
        thumbnailErrors: action.thumbnailErrors,
      })

      var creativesGroups = merge({}, state.campaignCreatives.creativesGroups)
      creativesGroups[action.groupIndex].creatives[0] = dynamicCreative
      return Object.assign({}, state, {
        campaignCreatives: Object.assign({}, state.campaignCreatives, {
          creativesGroups,
        }),
      })

    case CampaignCreationActionTypes.REMOVE_THUMBNAIL:
      var creatives = getCreativesFromState(state, action.groupIndex)
      let fieldsToUpdate = {
        thumbnail: null,
        thumbnailErrors: [],
        croppedImagesPerSource: {},
      }
      updateCreative(creatives, action, fieldsToUpdate)
      return updateCreativesState(state, creatives, action.groupIndex)

    case CampaignCreationActionTypes.REMOVE_DYNAMIC_THUMBNAIL:
      let dynamicFieldsToUpdate = {
        thumbnail: null,
        thumbnailErrors: [],
        croppedImagePerRatios: {},
        isThumbnailBeingUploaded: false,
        is_video: false,
      }
      var dynamicCreative = merge({}, state.campaignCreatives.dynamicCreative)
      if (isNullOrUndefined(action.groupIndex)) {
        updateDynamicThumbnail(dynamicCreative, action, dynamicFieldsToUpdate)

        return Object.assign({}, state, {
          campaignCreatives: Object.assign({}, state.campaignCreatives, {
            dynamicCreative,
          }),
        })
      }
      dynamicCreative = merge({}, state.campaignCreatives.creativesGroups[action.groupIndex].creatives[0])
      updateDynamicThumbnail(dynamicCreative, action, dynamicFieldsToUpdate)
      var creativesGroups = merge({}, state.campaignCreatives.creativesGroups)
      creativesGroups[action.groupIndex].creatives[0] = dynamicCreative
      return Object.assign({}, state, {
        campaignCreatives: Object.assign({}, state.campaignCreatives, {
          creativesGroups,
        }),
      })

    case CampaignCreationActionTypes.UPDATE_CREATIVE:
      var creatives = getCreativesFromState(state, action.groupIndex)
      updateCreative(creatives, action, {
        text: action.text,
        description: action.description,
        headline: action.headline,
        callToAction: action.callToAction,
      })
      return updateCreativesState(state, creatives, action.groupIndex)

    case CampaignCreationActionTypes.UPDATE_DYNAMIC_CREATIVE:
      var creativesGroups = merge({}, state.campaignCreatives.creativesGroups)
      var dynamicCreative = merge({}, state.campaignCreatives.dynamicCreative)
      if (isNullOrUndefined(action.groupIndex)) {
        dynamicCreative[action.fieldType][action.id] = action.text
      } else {
        creativesGroups[action.groupIndex].creatives[0][action.fieldType][action.id] = action.text
      }

      return Object.assign({}, state, {
        campaignCreatives: Object.assign({}, state.campaignCreatives, {
          dynamicCreative,
          creativesGroups,
        }),
      })

    case CampaignCreationActionTypes.REMOVE_CREATIVE:
      var creatives = getCreativesFromState(state, action.groupIndex)
      removeCreative(creatives, action)
      return updateCreativesState(state, creatives, action.groupIndex)

    case CampaignCreationActionTypes.CLONE_CREATIVE:
      var newCreativeId = state.campaignCreatives.creativeIdCounter + 1
      var creatives = getCreativesFromState(state, action.groupIndex)
      cloneCreative(creatives, action, newCreativeId)
      return updateCreativesState(state, creatives, action.groupIndex, newCreativeId)

    case CampaignCreationActionTypes.GET_CREATIVES:
      return Object.assign({}, state, {
        campaignCreatives: Object.assign({}, state.campaignCreatives, {
          isFetchingCreatives: true,
        }),
      })

    case CampaignCreationActionTypes.GET_CREATIVES_RESPONSE:
      var creatives = []
      var creativeId = 0
      var dynamicCreative = {}
      var sourceData = null

      if (action.campaign.provider_id === CampaignsConsts.GOOGLE_PROVIDER_ID) {
        // googleAds creative
        dynamicCreative = generateGoogleCreativeObject(action.creatives, action.campaign.ad_type)
      } else {
        action.creatives.forEach((creative) => {
          creatives.push(
            generateCreativeObject(
              {
                ...creative,
                id: creativeId++,
                text: creative.text ? creative.text : "",
                description: creative.description ? creative.description : "",
                headline: creative.headline ? creative.headline : "",
              },
              action.campaign.ad_type
            )
          )
        })
      }

      Object.keys(state.campaignSources.sources).forEach((sourceKey) => {
        if (state.campaignSources.sources[sourceKey].selected) {
          sourceData = state.campaignSources.sources[sourceKey]
        }
      })

      if (sourceData.isCreativesGroup) {
        return Object.assign({}, state, {
          campaignCreatives: Object.assign({}, state.campaignCreatives, {
            isFetchingCreatives: false,
            creativeIdCounter: creativeId,
            creativesGroups: creatives.length > 0 ? [{ isOpen: true, creatives }] : [],
            isDynamicCreative: action.campaign.ad_type === AdTypes.dynamic_ad.id,
          }),
        })
      }
      return Object.assign({}, state, {
        campaignCreatives: Object.assign({}, state.campaignCreatives, {
          isFetchingCreatives: false,
          creativeIdCounter: creativeId,
          creatives,
          dynamicCreative,
        }),
      })

    case CampaignCreationActionTypes.UPDATE_SOURCE:
      var campaignSources = Object.assign({}, state.campaignSources)
      var campaignSettings = Object.assign({}, state.campaignSettings)
      var sources = Object.assign({}, campaignSources.sources)

      // Resetting all other sources to be unselected, we can only have one source open at a time
      if (action.sourceData.selected) {
        Object.keys(sources).forEach((sourceKey) => {
          if (sourceKey !== action.sourceKey) {
            sources[sourceKey] = Object.assign({}, sources[sourceKey], { selected: false })
          }
        })
      }

      sources[action.sourceKey] = action.sourceData
      campaignSources.sources = sources

      if (action.sourceData?.selectedAdTypesIndexesArr?.length > 0) {
        campaignSources.sources[action.sourceKey].adTypes = campaignSources.sources[action.sourceKey].adTypes.map(
          (adType, i) => {
            if (i === action.sourceData.selectedAdTypesIndexesArr[0]) {
              return { ...adType, selected: true }
            } else {
              return { ...adType, selected: false }
            }
          }
        )
      }

      if (action.sourceData.id === CampaignsConsts.GOOGLE_PROVIDER_ID) {
        campaignSources.sources[action.sourceKey].cropDetails = generateCropDetails(
          action.sourceData.adTypes,
          action.sourceKey
        )
      }

      return Object.assign({}, state, {
        campaignSettings,
        campaignSources,
      })

    case CampaignCreationActionTypes.RESET_TARGETING_GROUPS: {
      return Object.assign({}, state, {
        campaignTargetingGroups: [],
      })
    }

    case CampaignCreationActionTypes.UPDATE_TARGETING_GROUP: {
      let campaignTargetingGroups = merge({}, state.campaignTargetingGroups)
      campaignTargetingGroups[action.groupIndex] = {
        ...campaignTargetingGroups[action.groupIndex],
        ...action.targetingGroupData,
      }

      return Object.assign({}, state, {
        campaignTargetingGroups,
      })
    }

    case CampaignCreationActionTypes.TOGGLE_TARGETING_GROUP_OPEN:
      let campaignTargetingGroups = merge({}, state.campaignTargetingGroups)

      campaignTargetingGroups.forEach((targetingGroup, groupIndex) => {
        if (groupIndex === action.groupIndex) {
          targetingGroup.isOpen = !targetingGroup.isOpen
        } else {
          targetingGroup.isOpen = false
        }
      })

      return Object.assign({}, state, {
        campaignTargetingGroups,
      })

    case CampaignCreationActionTypes.ADD_TARGETING_GROUP: {
      let campaignTargetingGroups = merge({}, state.campaignTargetingGroups)
      let targetingGroupToAdd = merge({}, campaignTargetingGroup)

      campaignTargetingGroups.forEach((targetingGroup) => {
        targetingGroup.isOpen = false
      })

      targetingGroupToAdd = {
        ...targetingGroupToAdd,
        ...merge({}, state.campaignSources.sources[action.selectedSourceKey]),
      }

      if (action.targetingGroupPreset) {
        targetingGroupToAdd = merge(targetingGroupToAdd, action.targetingGroupPreset, { arrayMerge: overwriteMerge })
        // Auto select account
        if (isNullOrUndefined(targetingGroupToAdd.providerAccount)) {
          const providerAccounts = state.providerAccounts.filter(
            (providerAccount) => targetingGroupToAdd.id === providerAccount.provider_id
          )
          if (providerAccounts.length > 0) {
            const randomIndex = Math.floor(Math.random() * providerAccounts.length)
            targetingGroupToAdd.providerAccount = providerAccounts[randomIndex]
          }
        }
      }

      campaignTargetingGroups.push(targetingGroupToAdd)

      return Object.assign({}, state, {
        campaignTargetingGroups,
      })
    }

    case CampaignCreationActionTypes.REMOVE_TARGETING_GROUP: {
      let campaignTargetingGroups = merge({}, state.campaignTargetingGroups)
      campaignTargetingGroups.splice(action.groupIndex, 1)

      return Object.assign({}, state, {
        campaignTargetingGroups,
      })
    }

    case CampaignCreationActionTypes.DUPLICATE_TARGETING_GROUP: {
      let campaignTargetingGroups = merge({}, state.campaignTargetingGroups)
      let targetingGroupToAdd = merge({}, action.targetingGroupData)

      campaignTargetingGroups.forEach((targetingGroup) => {
        targetingGroup.isOpen = false
      })

      campaignTargetingGroups.push(targetingGroupToAdd)

      return Object.assign({}, state, {
        campaignTargetingGroups,
      })
    }

    case CampaignCreationActionTypes.UPDATE_CAMPAIGN_DETAILS:
      var articleUrl = action.url
      var sources = merge({}, state.campaignSources.sources)

      if (!isNullOrEmpty(action.url)) {
        let protocol = "https://"
        articleUrl =
          protocol +
          articleUrl
            .replace("http://", "")
            .replace("https://", "")
            .replace(Consts.SPACES_IN_URL_BEFORE_QUERY_STRING_REGEX, "")
            .trim()

        if (articleUrl.includes("?")) {
          // If the url has a querystring, making sure that it has a slash before the params
          articleUrl = articleUrl.replace("/?", "?").replace("?", "/?")
          // Remove pages suffix (e.g. /24/)
          if (articleUrl.split("?")[0].match(Consts.ENDS_WITH_PAGES_REGEX)) {
            articleUrl =
              articleUrl.split("?")[0].replace(Consts.ENDS_WITH_PAGES_REGEX, "") + "/?" + articleUrl.split("?")[1]
          }
        } else {
          // If there's no querystring, making sure that the url ends with a trailing slash
          articleUrl = articleUrl.replace(Consts.ENDS_WITH_SLASH_REGEX, "") + "/"
          // Remove page suffix (e.g. /24/)
          if (articleUrl.match(Consts.ENDS_WITH_PAGES_REGEX)) {
            articleUrl = articleUrl.replace(Consts.ENDS_WITH_PAGES_REGEX, "") + "/"
          }
        }
      }

      return Object.assign({}, state, {
        campaignSettings: Object.assign({}, state.campaignSettings, {
          name: action.name,
          articleUrl,
          isRsoc: action?.isRsoc || false,
          selectedKeywords: action.selectedKeywords,
          articleIsArchived: action.articleIsArchived,
          articleKeywords: action.articleKeywords,
          layoutArticleUrl: articleUrl,
          siteId: action.siteId,
          networkId: action.networkId,
          networkCode: action.networkCode,
          siteDomain: action.siteDomain,
          possibleSitesForDomain: action.possibleSitesForDomain,
          layout: action.layout,
          queryParam: action.queryParam ? action.queryParam : null,
          selectedLanguages: action.selectedLanguages,
        }),
        campaignSources: Object.assign({}, state.campaignSources, {
          sources,
        }),
      })

    case CampaignCreationActionTypes.LAUNCHING_CAMPAIGNS:
      return Object.assign({}, state, {
        isLaunching: true,
      })

    case CampaignCreationActionTypes.UPDATE_RECENT_COUNTRIES:
      return Object.assign({}, state, {
        campaignSettings: Object.assign({}, state.campaignSettings, {
          recentCountries: action.recentCountries,
        }),
      })

    case CampaignCreationActionTypes.UPDATE_RECENT_LANGUAGES:
      return Object.assign({}, state, {
        campaignSettings: Object.assign({}, state.campaignSettings, {
          recentLanguages: action.recentLanguages,
        }),
      })

    case CampaignCreationActionTypes.OPEN_WIZARD_WITH_STATE:
      return merge(state, action.wizardState, { arrayMerge: overwriteMerge })

    case CampaignCreationActionTypes.UPDATE_SITE_PROVIDER_ACCOUNTS:
      return Object.assign({}, state, {
        providerAccounts: action.providerAccounts,
        isLoadingProviderAccounts: false,
      })

    case CampaignCreationActionTypes.RESET_SITE_PROVIDER_ACCOUNTS:
      var campaignSources = merge({}, state.campaignSources)

      Object.keys(campaignSources.sources).forEach((sourceKey) => {
        let source = campaignSources.sources[sourceKey]
        source.providerAccount = null
      })

      return Object.assign({}, state, {
        providerAccounts: [],
        isLoadingProviderAccounts: true,
        campaignSources,
      })

    case ActionTypes.NETWORK_TAGS_RESPONSE:
      let networkTags = []

      if (action.networkTags) {
        networkTags = action.networkTags.map((tag) => {
          return { name: tag }
        })
      }

      return Object.assign({}, state, {
        networkTags,
      })

    case ActionTypes.RESET_NETWORK_TAGS:
      return Object.assign({}, state, {
        networkTags: campaignCreationWizardInitialState.networkTags,
        campaignSettings: Object.assign({}, state.campaignSettings, {
          selectedTags: campaignCreationWizardInitialState.campaignSettings.selectedTags,
        }),
      })

    case CampaignCreationActionTypes.UPDATE_SELECTED_TAGS:
      return Object.assign({}, state, {
        campaignSettings: Object.assign({}, state.campaignSettings, {
          selectedTags: action.selectedTags,
        }),
      })

    case CampaignCreationActionTypes.LOAD_THUMBNAILS:
      var tmpDynamicCreative = Object.assign({}, state.campaignCreatives.dynamicCreative)
      var tmpCreativeGroups = merge({}, state.campaignCreatives.creativesGroups)
      switch (action.adTypeId) {
        case AdTypes.discovery_ad.id:
          tmpDynamicCreative = Object.assign({}, state.campaignCreatives.dynamicCreative, {
            displayThumbnails: [],
            thumbnails:
              Array.isArray(tmpDynamicCreative.thumbnails) && tmpDynamicCreative.thumbnails.length > 1
                ? tmpDynamicCreative.thumbnails.concat(
                    createThumbnails(20, Object.keys(tmpDynamicCreative.thumbnails).length)
                  )
                : createThumbnails(20),
          })
          break
        case AdTypes.display_ad.id:
          tmpDynamicCreative = Object.assign({}, state.campaignCreatives.dynamicCreative, {
            thumbnails:
              Array.isArray(tmpDynamicCreative.thumbnails) && tmpDynamicCreative.thumbnails.length > 1
                ? tmpDynamicCreative.thumbnails.concat(
                    createThumbnails(15, Object.keys(tmpDynamicCreative.thumbnails).length)
                  )
                : createThumbnails(15),
            displayThumbnails:
              Array.isArray(tmpDynamicCreative.displayThumbnails) && tmpDynamicCreative.displayThumbnails.length > 1
                ? tmpDynamicCreative.displayThumbnails.concat(
                    createThumbnails(10, Object.keys(tmpDynamicCreative.displayThumbnails).length)
                  )
                : createThumbnails(10),
          })
          break

        case AdTypes.dynamic_ad.id:
          if (
            !isNullOrEmpty(state.campaignCreatives?.isDynamicCreative) &&
            state.campaignCreatives.isDynamicCreative &&
            tmpCreativeGroups.length > 0 &&
            !isNullOrEmpty(tmpCreativeGroups[0].creatives) &&
            Array.isArray(tmpCreativeGroups[0].creatives) &&
            tmpCreativeGroups[0].creatives.length > 0
          ) {
            break
          }
          if (
            isNullOrEmpty(tmpCreativeGroups[0]) ||
            isNullOrEmpty(tmpCreativeGroups[0].creatives) ||
            !Array.isArray(tmpCreativeGroups[0].creatives)
          ) {
            tmpCreativeGroups = [
              {
                isOpen: true,
                creatives: [generateCreativeObject({ id: 0 }, action.adTypeId)],
              },
            ]
            break
          }
          // when the user switches from regular to dynamic, we need to convert the regular creatives to dynamic
          tmpCreativeGroups = [
            {
              isOpen: true,
              creatives: convertRegularCreativesToDynamic(tmpCreativeGroups[0].creatives),
            },
          ]
          break

        case AdTypes.regular_ad.id:
          if (
            tmpCreativeGroups.length > 0 &&
            !isNullOrEmpty(state.campaignCreatives?.isDynamicCreative) &&
            !state.campaignCreatives.isDynamicCreative &&
            !isNullOrEmpty(tmpCreativeGroups[0].creatives) &&
            Array.isArray(tmpCreativeGroups[0].creatives) &&
            tmpCreativeGroups[0].creatives.length > 0
          ) {
            break
          }
          if (
            isNullOrEmpty(tmpCreativeGroups[0]) ||
            isNullOrEmpty(tmpCreativeGroups[0].creatives) ||
            !Array.isArray(tmpCreativeGroups[0].creatives)
          ) {
            tmpCreativeGroups = [
              {
                isOpen: true,
                creatives: [generateCreativeObject({ id: 0 }, action.adTypeId)],
              },
            ]
            break
          }
          tmpCreativeGroups = [
            {
              isOpen: true,
              creatives: convertDynamicToRegular(tmpCreativeGroups[0].creatives),
            },
          ]
          break

        default:
          tmpDynamicCreative = Object.assign({}, state.campaignCreatives.dynamicCreative, {
            isDynamic: True,
            thumbnails: createThumbnails(20),
          })
      }
      return Object.assign({}, state, {
        campaignCreatives: Object.assign({}, state.campaignCreatives, {
          dynamicCreative: tmpDynamicCreative,
          creativesGroups: tmpCreativeGroups,
          isDynamicCreative: action.adTypeId === AdTypes.dynamic_ad.id,
          isFetchingCreatives: false,
        }),
      })

    case CampaignCreationActionTypes.UPDATE_DUPLICATE_REVIEW_WARNING_STATE:
      return Object.assign({}, state, {
        wasDuplicateReviewWarningShown: true,
      })

    default:
      return state
  }
}

export { campaignCreationReducer }
