import React from "react"
import { Filters } from "../common/consts/filterTypesV2"
import { bulkGetProviderBidStepSize } from "../utils/providerUtils"
import CampaignsTableConsts from "./campaignsTableConsts"

let CampaignsConsts = {
  CAMPAIGN_STATUS_RUNNING: "ACTIVE",
  CAMPAIGN_STATUS_PAUSED: "PAUSED",
  CAMPAIGN_STATUS_CAPPED: "CAPPED",
  CAMPAIGN_STATUS_REJECTED: "WITH_ISSUES",
  CAMPAIGN_STATUS_DELETED: "DELETED",
  CAMPAIGN_STATUS_REVIEW: "REVIEW",
  WW_COUNTRY_BULK_OPTION: {
    code: "WW",
    name: "World Wide",
    selected: false,
  },

  ADS_STATUS_LIST: {
    ACTIVE: "Approved",
    DELETED: "Deleted",
    ERROR: "Rejected",
    PAUSED: "Paused",
    REVIEW: "In review",
    OTHER: "Other",
  },

  CAMPAIGN_STATUS_NAMES: {
    ACTIVE: "Active",
    DELETED: "Deleted",
    WITH_ISSUES: "With Issues",
    PAUSED: "Paused",
    CAPPED: "CAPPED",
  },

  CAMPAIGN_LEARNING_STATUS_NAMES: {
    LEARNING: "Learning",
    FAIL: "Learning Limited",
    LEARNING_LIMITED: "Learning Limited",
    EXPEDITED_LEARNING: "Learning Expedited",
  },

  LIST_COLUMNS: CampaignsTableConsts,

  CAMPAIGN_SEARCH_MAX_RESULTS: 20,
  CAMPAIGNS_ADVANCED_SEARCH_PAGE_SIZE: 100,
  CAMPAIGN_DEFAULT_CONSTANT_ROI: 150,
  CAMPAIGN_DEFAULT_CONSTANT_ROAS_ROI: 100,
  CAMPAIGN_REPORT_ERROR_MESSAGE: "There was an error generating the report",
  CAMPAIGN_PERFORMANCE_BY_PAGE_ERROR: "There was an error generating performance by page report",
  CAMPAIGN_STATUS_CHANGE_ERROR_MESSAGE: "There was an error changing the campaign status",
  EXPORT_USER_GENERATED_REPORT_ERROR_MESSAGE: "There was an error exporting the report",
  TABOOLA_PROVIDER_ID: 1,
  FACEBOOK_PROVIDER_ID: 9,
  GOOGLE_PROVIDER_ID: 91,
  OUTBRAIN_PROVIDER_ID: 4,
  PINTEREST_PROVIDER_ID: 102,
  BASE_PROVIDER_IDS: [1, 91, 102],

  // campaign filters divided by sections
  CAMPAIGNS_FILTERS: [
    // first section
    [Filters.CAMPAIGN_SAVED_FILTERS],

    // second section
    [
      Filters.CAMPAIGN_SOURCE,
      Filters.CAMPAIGN_COUNTRY,
      Filters.CAMPAIGN_COUNTRY_GROUP,
      Filters.CAMPAIGN_DEVICE_TARGETING_GROUP,
      Filters.CAMPAIGN_DEVICE,
      Filters.CAMPAIGN_SITE,
      Filters.CAMPAIGN_NETWORK,
    ],

    // third section
    [
      Filters.CAMPAIGN_STATUS,
      Filters.CAMPAIGN_PROVIDER_PUBLISHER_ID,
      Filters.CAMPAIGN_TARGETING_LANGUAGE,
      Filters.ARTICLE_LANGUAGE,
      Filters.CAMPAIGN_BID_TYPE,
      Filters.CAMPAIGN_CREATED_BY,
      Filters.CAMPAIGN_TAGS,
      Filters.CAMPAIGN_CREATE_DATE,
      Filters.CAMPAIGN_LAST_MODIFIED,
      Filters.CAMPAIGN_LAST_MODIFIED_ACTION,
      Filters.CAMPAIGN_ACTIVATION_DATE,
      Filters.CAMPAIGN_INITIAL_ACTIVATION_DATE,
      Filters.CAMPAIGN_VISITS,
      Filters.CAMPAIGN_CLICKS,
      Filters.CAMPAIGN_RESULTS,
      Filters.CAMPAIGN_RESULTS_RATE,
      Filters.CAMPAIGN_AVERAGE_CPC,
      Filters.CAMPAIGN_BID,
      Filters.CAMPAIGN_TOTAL_BUDGET,
      Filters.CAMPAIGN_DAILY_BUDGET,
      Filters.CAMPAIGN_LEARNING_STATUS,
      Filters.CAMPAIGN_CLICK_VALUE,
      Filters.CAMPAIGN_VISIT_COST,
      Filters.CAMPAIGN_COST_PER_RESULT,
      Filters.CAMPAIGN_ADS_STATUS,
      Filters.CAMPAIGN_VISIT_PROFIT,
      Filters.AD_TYPE,
      Filters.CONVERSION_GOAL,
      Filters.CONVERSION_GOAL_VALUE,
      Filters.CAMPAIGN_RPM_PAGE_VIEWS,
      Filters.ACCOUNT_ID,
      Filters.BROWSERS,
      Filters.OPERATING_SYSTEMS,
      Filters.ARTICLE_STYLE,
    ],

    // fourth section
    [
      Filters.CAMPAIGN_VISIT_VALUE,
      Filters.CAMPAIGN_VISIT_DURATION,
      Filters.CAMPAIGN_ROI,
      Filters.CAMPAIGN_VISIT_ROI,
      Filters.CAMPAIGN_SPEND,
      Filters.CAMPAIGN_PROFIT,
      Filters.CAMPAIGN_RPM,
    ],

    // fifth section
    [
      Filters.CAMPAIGN_PPS,
      Filters.CAMPAIGN_BOUNCE_RATE,
      Filters.CAMPAIGN_CTR,
      Filters.CAMPAIGN_QUALITY_RANKING,
      Filters.CAMPAIGN_ENGAGEMENT_RATE_RANKING,
      Filters.CAMPAIGN_CONVERSION_RATE_RANKING,
    ],
  ],

  CAMPAIGN_ADS_STATUS: {
    ALL_REJECTED: 0,
    SOME_REJECTED: 1,
    ALL_APPROVED: 2,
    ALL_REVIEW: 3,
  },

  CAMPAIGN_ITEMS_PER_PAGE_OPTIONS: [50, 75, 100],

  REPORT_TYPES: {
    PREDICTED_REPORT: 1,
  },

  CAMPAIGN_RANKINGS: {
    0: "Unknown",
    1: "Below Average (Bottom 10%)",
    2: "Below Average (Bottom 20%)",
    3: "Below Average (Bottom 35%)",
    4: "Average",
    5: "Above Average",
  },

  TABOOLA_SAFETY_LEVELS: {
    Medium: "Not Safe",
    High: "Safe",
  },

  // campaign advanced search filters divided by sections
  CAMPAIGNS_ADVANCED_SEARCH_FILTERS: [
    // first section
    [
      Filters.CAMPAIGN_SOURCE,
      Filters.CAMPAIGN_COUNTRY,
      Filters.CAMPAIGN_COUNTRY_GROUP,
      Filters.CAMPAIGN_DEVICE_TARGETING_GROUP,
      Filters.CAMPAIGN_DEVICE,
      Filters.CAMPAIGN_SITE,
      Filters.CAMPAIGN_NETWORK,
    ],

    // second section
    [
      Filters.CAMPAIGN_STATUS,
      Filters.CAMPAIGN_PROVIDER_PUBLISHER_ID,
      Filters.CAMPAIGN_ADVANCED_SEARCH_LANGUAGE,
      Filters.CAMPAIGN_ADVANCED_SEARCH_CREATED_BY,
      Filters.CAMPAIGN_ADVANCED_SEARCH_TAGS,
      Filters.CAMPAIGN_CREATE_DATE,
      Filters.CAMPAIGN_INITIAL_ACTIVATION_DATE,
      Filters.CAMPAIGN_BID,
      Filters.CAMPAIGN_BID_TYPE,
      Filters.CAMPAIGN_TOTAL_BUDGET,
      Filters.CAMPAIGN_DAILY_BUDGET,
      Filters.CAMPAIGN_ACTIVATION_DATE,
      Filters.AD_TYPE,
      Filters.CONVERSION_GOAL,
      Filters.CONVERSION_GOAL_VALUE,
      Filters.ACCOUNT_ID,
      Filters.BROWSERS,
      Filters.OPERATING_SYSTEMS,
    ],

    // third section
    [Filters.CAMPAIGN_ADS_STATUS],
  ],
}

CampaignsConsts.CAMPAIGNS_BULK_MULTI_EDIT_MODE = {
  EDIT_BUDGET: 0,
  EDIT_TARGET_ROI: 1,
  EDIT_BID: 2,
  EDIT_TAGS: 3,
  DUPLICATE: 4,
  ACTIVATION: 5,
}

CampaignsConsts.CAMPAIGNS_BULK_MULTI_EDIT_OPTIONS = {
  // Controlling the appearance and order of options in the multi bulk edit menu, the "action" key is only for recognizing the mode
  // later when performing operations, it has no UI effect (for example: to identify which mode we were on while clicking "save" in order for the service
  // to know which API request to make)
  [CampaignsConsts.CAMPAIGNS_BULK_MULTI_EDIT_MODE.EDIT_BUDGET]: [
    { action: "BudgetFixedAmount", text: "Fix", currencyPrefix: true, placeholder: "Enter a fixed amount..." },
    {
      action: "BudgetAdjustByAmount",
      text: "$",
      allowNegative: true,
      currencyPrefix: true,
      placeholder: "$  ",
      hasStepper: true,
      stepperSize: 1,
    },
    {
      action: "BudgetAdjustByPercent",
      text: "%",
      allowNegative: true,
      suffix: "%",
      placeholder: "   %",
      hasStepper: true,
      stepperSize: 1,
      minValue: -100,
    },
  ],
  [CampaignsConsts.CAMPAIGNS_BULK_MULTI_EDIT_MODE.EDIT_BID]: [
    {
      action: "BidFixedAmount",
      text: "Fix",
      currencyPrefix: true,
      placeholder: "$  ",
      decimalScale: (campaigns) => bulkGetProviderBidStepSize(campaigns).countDecimals(),
    },
    {
      action: "BidAdjustByInputValueTypes",
      text: "+ / -",
      allowNegative: true,
      hasStepper: true,
      inputValueTypes: {
        $: {
          stepperSize: (campaigns) => bulkGetProviderBidStepSize(campaigns),
          currencyPrefix: true,
          suffix: "",
          placeholder: "$  ",
          minValue: null,
        },
        "%": {
          stepperSize: 1,
          prefix: "",
          suffix: "%",
          placeholder: "   %",
          minValue: -100,
        },
      },
    },
  ],
  [CampaignsConsts.CAMPAIGNS_BULK_MULTI_EDIT_MODE.EDIT_TAGS]: { isCustomMenu: true },
  [CampaignsConsts.CAMPAIGNS_BULK_MULTI_EDIT_MODE.DUPLICATE]: { isCustomMenu: true },
  [CampaignsConsts.CAMPAIGNS_BULK_MULTI_EDIT_MODE.ACTIVATION]: { isCustomMenu: true },
}

CampaignsConsts.LAST_MODIFIED_ACTION_TYPES = {
  CHANGE_BID: "change_bid",
  CHANGE_STATUS: "change_status",
  CHANGE_DAILY_BUDGET: "change_daily_budget",
  CHANGE_TOTAL_BUDGET: "change_total_budget",
  TARGET_ROI: "target_roi",
  CHANGE_ROAS: "change_roas",
  SET_TARGETING: "set_targeting",
}

CampaignsConsts.LAST_MODIFIED_ACTION_NAMES = {
  [CampaignsConsts.LAST_MODIFIED_ACTION_TYPES.CHANGE_BID]: "Bid Change",
  [CampaignsConsts.LAST_MODIFIED_ACTION_TYPES.CHANGE_STATUS]: "Status Change",
  [CampaignsConsts.LAST_MODIFIED_ACTION_TYPES.CHANGE_DAILY_BUDGET]: "Daily Budget Change",
  [CampaignsConsts.LAST_MODIFIED_ACTION_TYPES.CHANGE_TOTAL_BUDGET]: "Total Budget Change",
  [CampaignsConsts.LAST_MODIFIED_ACTION_TYPES.CHANGE_ROAS]: "ROAS Change",
}

Object.freeze(CampaignsConsts)
export default CampaignsConsts
