import axios from "axios"
import Consts from "../app/consts"

export default class UserService {
  static getCurrentuser() {
    return axios.get(Consts.BASE_API_URL + "/api/user")
  }

  static uploadProfilePictureToS3(url, file) {
    let options = {
      headers: {
        "Content-Type": file.type,
        should_include_authorization: false,
      },
    }

    return axios.put(url, file, options)
  }

  static updateUserProfile({
    pictureUrl = null,
    firstName = null,
    lastName = null,
    email = null,
    phoneNumber = null,
  } = {}) {
    return axios.post(Consts.BASE_API_URL + "/api/user/profile", {
      picture: pictureUrl,
      is_using_google_picture: pictureUrl ? 0 : null,
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      email: email,
    })
  }

  static getCustomInterfaceV2() {
    return axios.get(Consts.BASE_API_URL + "/api/user/custom_interface")
  }

  static updateCustomInterfaceRowV2(name, section, order, status) {
    return axios.post(Consts.BASE_API_URL + "/api/user/custom_interface", {
      name: name,
      section: section,
      order: order,
      status: status,
    })
  }

  static updateCustomInterfaceSectionV2(sectionName, section) {
    return axios.post(Consts.BASE_API_URL + "/api/user/custom_interface/" + sectionName, {
      rows: section,
    })
  }
}
