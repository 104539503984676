class ModuleTypes {}

// features
ModuleTypes.CAMPAIGN_STATUS_CHANGE = "feature.campaign_status_change"
ModuleTypes.CAMPAIGN_CREATION = "feature.campaign_creation"
ModuleTypes.CAMPAIGN_BID_CHANGE = "feature.campaign_bid_change"
ModuleTypes.CAMPAIGN_ACTIVATION = "feature.campaign_activation"
ModuleTypes.CAMPAIGNS_EXPORT = "feature.campaigns_export"
ModuleTypes.EDIT_CAMPAIGN = "feature.edit_campaign"
ModuleTypes.SUDO_USERS = "view.sudo_users"
ModuleTypes.ACCOUNT_SETTINGS = "feature.account_settings"
ModuleTypes.COMMERCE_MANAGER = "feature.commerce_manager"
ModuleTypes.CONNECT_FACEBOOK_ACCOUNT = "feature.connect_facebook_account"
ModuleTypes.CONNECT_PINTEREST_ACCOUNT = "feature.connect_pinterest_account"
ModuleTypes.CAMPAIGN_SYNDICATION = "feature.campaign_syndication"
ModuleTypes.AUTOMATION_CREATION = "feature.automation_creation"
ModuleTypes.AUTOMATION_STATUS_CHANGE = "feature.automation_status_change"
ModuleTypes.AUTO_GENERATE_TEXT = "feature.auto_generate_text"
ModuleTypes.BULK_MULTIPLE_CAMPAIGNS = "feature.duplicate_multiple_campaigns"

// views
ModuleTypes.CAMPAIGN_MANAGEMENT = "view.campaign-management"
ModuleTypes.TRAFFIC_SOURCES = "view.traffic-sources"
ModuleTypes.RULES = "view.rules"
ModuleTypes.ACCOUNT = "view.account"
ModuleTypes.CAMPAIGN_CREATION_SETTINGS = "view.campaign_creation_settings"
ModuleTypes.DASHBOARD = "view.dashboard"
ModuleTypes.POLICY_REVIEW = "view.policy-review"
ModuleTypes.DRAFTS = "view.drafts"
ModuleTypes.ARTICLES = "view.articles"
export default ModuleTypes
