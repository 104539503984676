import React, { useEffect, useState } from "react"
import { CircularProgress } from "@material-ui/core"
import { connect } from "react-redux"
import GenericDropdown from "../../../../common/components/genericDropdown"
import { updateTargetingGroup } from "../../../campaignCreationActions"
import Consts from "../../../../app/consts"
import ProviderAccountService from "../../../../api/providerAccountService"
import { notificationAdd } from "../../../../common/actions/commonActions"
import { isNullOrUndefined } from "../../../../utils/funcUtils"
import CampaignsConsts from "../../../../campaignsV2/campaignsConsts"

const ProviderAccountDropdown = (props) => {
  const {
    providerAccounts,
    isLoadingProviderAccounts,
    campaignSettings,
    campaignTargetingGroups,
    providerAccountValidationErrors,
    groupIndex,
    providerId,
    dispatch,
  } = props

  const [menuOpen, setMenuOpen] = useState(false)
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)
  const [selectedIndexesArr, setSelectedIndexesArr] = useState([])
  const [isLoadingAutoSelect, setIsLoadingAutoSelect] = useState(false)

  const [selectedAccountType, setSelectedAccountType] = useState("ALL")
  const [accountTypeMenuOpen, setAccountTypeMenuOpen] = useState(false)
  const [accountTypeAnchorEl, setAccountTypeAnchorEl] = useState(null)
  const [accountTypeSelectedIndex, setAccountTypeSelectedIndex] = useState([0])

  const [menuItems, setMenuItems] = useState([])
  const [accountTypeOptions, setAccountTypeOptions] = useState([])

  const campaignTargetingGroup = campaignTargetingGroups[groupIndex]

  useEffect(() => {
    const incomingType = campaignTargetingGroup?.selectedAccountType
    if (
      !isNullOrUndefined(incomingType) &&
      accountTypeOptions.includes(incomingType) &&
      incomingType !== selectedAccountType
    ) {
      setSelectedAccountType(incomingType)
      const index = accountTypeOptions.indexOf(incomingType)
      setAccountTypeSelectedIndex([index])
    }
  }, [campaignTargetingGroup?.selectedAccountType])

  // Update account type options and filtered items whenever providerAccounts change
  useEffect(() => {
    const types = providerAccounts.map((acc) => acc.account_type?.toUpperCase())
    const uniqueTypes = Array.from(new Set(types))
    const sortedTypes = ["ALL", ...uniqueTypes.filter((t) => t !== "ALL")]

    setAccountTypeOptions(sortedTypes)

    if (!sortedTypes.includes(selectedAccountType)) {
      setSelectedAccountType("ALL")
      setAccountTypeSelectedIndex([0])
    }
  }, [providerAccounts])

  // Update menu items when selectedAccountType or providerAccounts change
  useEffect(() => {
    const filtered = providerAccounts.filter((acc) => {
      const accType = acc.account_type?.toUpperCase()
      return accType === selectedAccountType
    })

    setMenuItems(filtered.map((acc) => ({ name: acc.name })))
  }, [providerAccounts, selectedAccountType])

  // Auto-select logic
  useEffect(() => {
    if (menuItems.length > 0) {
      autoSelectProviderAccount()
    }
  }, [menuItems])

  const autoSelectProviderAccount = () => {
    if (menuItems.length === 1) {
      const account = getFilteredProviderAccountByName(menuItems[0].name)
      if (JSON.stringify(campaignTargetingGroup.providerAccount) !== JSON.stringify(account)) {
        selectProviderAccount(account)
      }
    } else if (menuItems.length > 1 && isNullOrUndefined(campaignTargetingGroup.providerAccount)) {
      const randomIndex = Math.floor(Math.random() * menuItems.length)
      const randomAccount = getFilteredProviderAccountByName(menuItems[randomIndex].name)
      selectProviderAccount(randomAccount)
    }
  }

  const getFilteredProviderAccountByName = (name) => providerAccounts.find((acc) => acc.name === name)

  const selectProviderAccount = (providerAccount) => {
    if (providerAccount.account_id === Consts.PROVIDER_ACCOUNT_AUTO_SELECT_OBJ.account_id) {
      setIsLoadingAutoSelect(true)
      ProviderAccountService.getSiteIdealProviderAccount(providerId, campaignSettings.siteId, selectedAccountType)
        .then((result) => {
          if (result?.data?.provider_account) {
            const matched = providerAccounts.find(
              (pa) => pa.sub_account_id === result.data.provider_account.sub_account_id
            )
            if (matched) {
              selectProviderAccount(matched)
            }
          }
        })
        .catch(() => {
          dispatch(notificationAdd("An error occurred while trying to auto-select provider account"))
        })
        .finally(() => setIsLoadingAutoSelect(false))
    } else {
      updateProviderAccount(providerAccount)
    }
  }

  const updateProviderAccount = (selectedProviderAccount) => {
    const targetingGroupData = campaignTargetingGroups[groupIndex]
    const updated = {
      ...targetingGroupData,
      providerAccount: selectedProviderAccount,
      selectedAccountType: selectedAccountType,
    }
    dispatch(updateTargetingGroup(groupIndex, updated))
  }

  const handleMenuOpen = (event) => {
    setMenuOpen(true)
    setMenuAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setMenuOpen(false)
  }

  const handleMenuClick = (index) => {
    setSelectedIndexesArr(index)
    const selected = getFilteredProviderAccountByName(menuItems[index].name)
    selectProviderAccount(selected)
    handleMenuClose()
  }

  const handleAccountTypeMenuOpen = (event) => {
    setAccountTypeMenuOpen(true)
    setAccountTypeAnchorEl(event.currentTarget)
  }

  const handleAccountTypeMenuClose = () => {
    setAccountTypeMenuOpen(false)
  }

  const handleAccountTypeSelect = (index) => {
    const newType = accountTypeOptions[index]
    setSelectedAccountType(newType)
    setAccountTypeSelectedIndex([index])
    handleAccountTypeMenuClose()
  }

  const isFieldDisabled = !campaignSettings.siteId
  let header = "Choose account"
  let buttonClassName = "creation-generic-dropdown-button "

  if (campaignTargetingGroup.providerAccount) {
    buttonClassName += "selected"
    header = campaignTargetingGroup.providerAccount.name
  }

  if (isLoadingProviderAccounts || isLoadingAutoSelect) {
    header = <CircularProgress size={20} />
  }

  return (
    <div
      className={
        "text-field provider-account-selection " +
        (providerAccountValidationErrors ? "error " : "") +
        (isFieldDisabled ? "disabled " : "")
      }
    >
      {providerId === CampaignsConsts.TABOOLA_PROVIDER_ID && (
        <>
          <div className="creation-generic-dropdown-label">Account Type</div>
          <GenericDropdown
            header={selectedAccountType}
            selectOptionCallback={handleAccountTypeSelect}
            options={accountTypeOptions.map((label) => ({ name: label }))}
            open={accountTypeMenuOpen}
            anchorEl={accountTypeAnchorEl}
            selectedIndexes={accountTypeSelectedIndex}
            openDropdownCallback={handleAccountTypeMenuOpen}
            closeDropdownCallback={handleAccountTypeMenuClose}
            allowNoSelection={false}
            buttonClassName="creation-generic-dropdown-button"
            menuClassName="creation-generic-dropdown-menu"
          />
        </>
      )}

      <div className="creation-generic-dropdown-label">Provider Account</div>
      <GenericDropdown
        header={header}
        selectOptionCallback={handleMenuClick}
        options={menuItems}
        open={menuOpen}
        anchorEl={menuAnchorEl}
        selectedIndexes={selectedIndexesArr}
        openDropdownCallback={handleMenuOpen}
        allowAutoSuggest={true}
        closeDropdownCallback={handleMenuClose}
        allowNoSelection={false}
        buttonClassName={buttonClassName}
        menuClassName="creation-generic-dropdown-menu"
      />
      {providerAccountValidationErrors}
    </div>
  )
}

function mapStateToProps(state, ownProps) {
  const allProviderAccountsForSite = state.campaignCreationWizard.providerAccounts
  let providerAccounts = []
  let providerId = null

  Object.keys(state.campaignCreationWizard.campaignSources.sources).forEach((sourceKey) => {
    const source = state.campaignCreationWizard.campaignSources.sources[sourceKey]
    if (!source.selected) return

    providerId = source.id

    providerAccounts = allProviderAccountsForSite.filter((account) => providerId === account.provider_id)

    if (
      sourceKey === "Facebook" &&
      state.campaignCreationWizard.campaignSettings.siteId &&
      providerAccounts.length > 0
    ) {
      providerAccounts.unshift({
        ...Consts.PROVIDER_ACCOUNT_AUTO_SELECT_OBJ,
        site_id: state.campaignCreationWizard.campaignSettings.siteId,
      })
    }
  })

  return {
    isLoadingProviderAccounts: state.campaignCreationWizard.isLoadingProviderAccounts,
    campaignSettings: state.campaignCreationWizard.campaignSettings,
    campaignTargetingGroups: state.campaignCreationWizard.campaignTargetingGroups,
    providerAccounts,
    providerId,
  }
}

export default connect(mapStateToProps)(ProviderAccountDropdown)
